enum MODALS {
  Approve = "Approve",
  CreateContent = "CreateContent",
  CreateChallenge = "CreateChallenge",
  CreateTest = "CreateTest",
  SendAnnouncement = "SendAnnouncement",
  ViewAnnouncement = "ViewAnnouncement",
  UploadContent = "UploadContent",
  AddUser = "AddUser",
  CreateTeam = "CreateTeam",
  TeamType = "TeamType",
  DistributeCourse = "DistributeCourse",
}

export default MODALS;
