import * as yup from "yup";

import { remark } from "remark";
import strip from "strip-markdown";
import he from "he";
import QUESTION_STATUSES from "../constants/questionStatuses";

const questionSchema = yup.object().shape({
  status: yup.number(),
  text: yup
    .string()
    .transform((value) => {
      const plainText = he.decode(
        remark().use(strip).processSync(value).toString()
      ); // transform markdown to string for validation

      return plainText.trim() || "";
    })
    .max(5000, "Content must be at most 5000 characters")
    .when("status", {
      is: (status: number) => status === QUESTION_STATUSES.Visible,
      then: yup
        .string()
        .required("Question is required")
        .min(3, "Question must be at least 3 characters"),
    }),
  videoUrl: yup
    .string()
    .nullable()
    .url()
    .test(
      "isValid",
      'Unsupported video platform, supported: "youtube.com", "vimeo.com" ',
      (value) => {
        try {
          if (value) {
            const supportedHostnames = [
              "www.youtube.com",
              "youtube.com",
              "www.vimeo.com",
              "vimeo.com",
              "www.youtu.be",
              "youtu.be",
            ];
            const url = new URL(value);

            return supportedHostnames.includes(url.hostname);
          }

          return true;
        } catch (error) {
          return false;
        }
      }
    ),
  contentUrl: yup.string().nullable().url(),
  infoUrl: yup.string().nullable().url(),
  answers: yup
    .array()
    .of(
      yup.object().shape({
        text: yup
          .string()
          .transform((value) => value.trim() || "")
          .min(1, "Answer must be at least 1 character")
          .required("Answer text is required"),
        isCorrect: yup.boolean(),
      })
    )
    .nullable(),
});

export default questionSchema;
