import { Tooltip, TooltipProps } from "@mui/material";

import QuestionCircle from "./Icons/QuestionCircle";

const Helper: React.FC<Omit<TooltipProps, "children">> = (props) => {
  return (
    <Tooltip {...props}>
      <span>
        <QuestionCircle />
      </span>
    </Tooltip>
  );
};

export default Helper;
