import { Paper, PaperProps } from "@mui/material";

const CustomPaper: React.FC<PaperProps> = ({ children, sx, ...rest }) => {
  return (
    <Paper
      sx={{
        border: "1px solid black",
        borderRadius: 4,
        overflow: "hidden",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Paper>
  );
};

export default CustomPaper;
