import { useState } from "react";

import {
  Avatar,
  Box,
  Fab,
  IconButton,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Plane from "../Icons/Plane";

import logoSmall from "../../assets/img/logo-small.png";

import { Controller, useForm } from "react-hook-form";
import { IMessage, MessageType } from "../../types/messages";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { yupResolver } from "@hookform/resolvers/yup";
import chatSchema from "../../schemas/chat";
import { track } from "@amplitude/analytics-browser";

import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";

import configParams from "../../config/params";

const Chat: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<IMessage[]>([]);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: "",
    },
    resolver: yupResolver(chatSchema),
  });

  const onSubmit = (values: { message: string }) => {
    setMessages((messages) => [
      ...messages,
      { text: values.message, type: MessageType.User },
    ]);
    setIsLoading(true);

    track("SendChatbotMessageWeb");

    axios
      .get<string>("/Web/ChatCompletion", {
        params: {
          userMessage: values.message,
        },
      })
      .then((res) => {
        console.log("res:", res);
        setMessages((messages) => [
          ...messages,
          { text: res.data, type: MessageType.Bot },
        ]);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });

    reset();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget.parentElement);
    track("OpenChatbotWeb");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (!configParams.webDomains.includes(window.location.host)) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          paddingTop: 2,
        }}
      >
        <Fab color="default" aria-label="chat" onClick={handleClick}>
          <img src={logoSmall} alt="logo" width={30} height={30} />
        </Fab>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        elevation={0}
        slotProps={{
          paper: {
            sx: {
              marginBottom: 2,
              maxWidth: 500,
              minWidth: 500,
              padding: 1,
              borderRadius: 4,
            },
          },
        }}
      >
        <Stack
          spacing={2}
          style={{
            padding: 2,
            maxHeight: 500,
            overflowY: "auto",
          }}
        >
          {messages.map((message, index) => {
            if (message.type === MessageType.Bot) {
              return (
                <Stack key={index} direction="row" spacing={1}>
                  <Avatar>
                    <img src={logoSmall} alt="logo" width={24} height={24} />
                  </Avatar>
                  <Box
                    sx={{
                      marginRight: 10,
                      backgroundColor: "#FAFAF9",
                      borderRadius: 12,
                      padding: 3,
                    }}
                  >
                    {/* @ts-ignore */}
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {message.text}
                    </ReactMarkdown>
                  </Box>
                </Stack>
              );
            }

            return (
              <Box key={index}>
                <Typography
                  sx={{
                    marginLeft: 14,
                    backgroundColor: "#E7E5E4",
                    borderRadius: 12,
                    padding: 3,
                  }}
                >
                  {message.text}
                </Typography>
              </Box>
            );
          })}

          {isLoading && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar>
                <img src={logoSmall} alt="logo" width={24} height={24} />
              </Avatar>
              <BeatLoader loading={true} color="#a9a9a9" />
            </Stack>
          )}
        </Stack>
        <Box
          sx={{
            pt: 4,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <IconButton type="submit">
                        <Plane
                          style={{
                            transform: "rotate(90deg)",
                          }}
                        />
                      </IconButton>
                    ),
                  }}
                  inputProps={{
                    autoComplete: "one-time-code",
                  }}
                />
              )}
            />
          </form>
        </Box>
      </Popover>
    </>
  );
};

export default Chat;
