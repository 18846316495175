import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";

import {
  Grid,
  IconButton,
  Stack,
  Chip,
  Typography,
  Link,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { track } from "@amplitude/analytics-browser";
import Helper from "../../../components/Helper";
import { DistributeCoursesHelper } from "../../../constants/helpers";

import NoTeams from "./NoTeams";
import NoCourses from "./NoCourses";

import Plus from "../../../components/Icons/Plus";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { openModal } from "../../../features/modals";
import MODALS from "../../../constants/modals";

import moment from "moment";

import Analytics from "../../../components/Icons/Analytics";
import Edit from "../../../components/Icons/Edit";
import {
  ICourseDistribution,
  IGetCourseDistributionsResponse,
} from "../../../types/distributeCourse";
import { COURSE_TRAINING_TYPE_LIST } from "../../../constants/courseTrainingType";

import { useLocation } from "react-router";
import { setRun } from "../../../features/tour";

const DistributeCourses = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const tour = useAppSelector((state) => state.tour);

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<IGetCourseDistributionsResponse>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const loadData = useCallback(() => {
    setLoading(true);

    axios
      .get<IGetCourseDistributionsResponse>(
        "/CourseDistributions/GetCourseDistributions",
        {
          params: { page: paginationModel.page + 1 },
        }
      )
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paginationModel.page]);

  useEffect(() => {
    loadData();
    track("OpenedDistributeCourses");
  }, [loadData]);

  const columns: GridColDef<ICourseDistribution>[] = [
    {
      field: "courseName",
      headerName: "Course",
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{params.value}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "channelName",
      headerName: "Team",
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{params.value}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start date",
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        if (!params.value || moment(params.value).year() < 2000) {
          return "-";
        }

        return moment(params.value).format("L");
      },
    },
    {
      field: "endDate",
      headerName: "End date",
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        if (!params.value || moment(params.value).year() < 2000) {
          return "-";
        }

        return moment(params.value).format("L");
      },
    },
    {
      field: "days",
      headerName: "Receive lessons on",
      sortable: false,
      minWidth: 240,
      renderCell: (params) => {
        const avatars = moment.weekdays(true).map((day: string) => {
          // @ts-ignore
          const isScheduled = params.row[day.toLowerCase()];

          if (isScheduled) {
            return (
              <Avatar
                key={day}
                sx={{
                  bgcolor: "text.primary",
                  width: 24,
                  height: 24,
                  fontSize: 12,
                }}
              >
                {day[0]}
                {day[1]}
              </Avatar>
            );
          }
          return null;
        });

        if (avatars.filter((day) => day !== null).length === 0) {
          return "-";
        }

        return (
          <Stack direction="row" spacing={1}>
            {avatars}
          </Stack>
        );
      },
    },
    {
      field: "courseTrainingType",
      headerName: "Training type",
      sortable: false,
      minWidth: 180,
      renderCell: (params) => {
        const courseTrainingType =
          COURSE_TRAINING_TYPE_LIST[params.row.courseTrainingType];

        return (
          <Chip
            label={courseTrainingType.name}
            style={{
              backgroundColor: courseTrainingType.color,
            }}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <Stack direction="row">
            <Link
              className="edit-team-button"
              component={RouterLink}
              to={`/analytics/teams/${params.row.channelId}/course/${params.row.courseTrainingType}/${params.row.courseDistributionId}`}
            >
              <IconButton>
                <Analytics />
              </IconButton>
            </Link>
            <IconButton
              onClick={() => {
                dispatch(
                  openModal({
                    type: MODALS.DistributeCourse,
                    initialValues: params.row,
                    callback: loadData,
                  })
                );
              }}
            >
              <Edit />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if ((location.state as { course?: number })?.course) {
      dispatch(
        openModal({
          type: MODALS.DistributeCourse,
          course: (location.state as { course?: number }).course,
          callback: loadData,
        })
      );
      window.history.replaceState({}, "");
    }
  }, [location.state]);

  useEffect(() => {
    if (data && data.teamCount > 0) {
      if (tour.index === 9) {
        dispatch(setRun(true));
      }
    }

    if (tour.index === 14 && !tour.finished && !tour.run) {
      setTimeout(() => {
        dispatch(setRun(true));
        loadData();
      }, 1500);
    }
  }, [tour, data]);

  return (
    <Stack spacing={2} flex={1}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Distribute Courses
            </Typography>
            <Helper title={<DistributeCoursesHelper />} />
          </Stack>
        </Grid>
        {data?.courseDistributions && data?.courseDistributions.length > 0 && (
          <Grid item>
            <Button
              className="distribute-course-button"
              variant="contained"
              startIcon={<Plus width={25} height={25} color="#fff" />}
              onClick={() => {
                dispatch(
                  openModal({
                    type: MODALS.DistributeCourse,
                    callback: loadData,
                  })
                );
              }}
            >
              Distribute a course
            </Button>
          </Grid>
        )}
      </Grid>

      {isLoading ? (
        <Stack flex={1} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : data?.teamCount === 0 ? (
        <NoTeams />
      ) : data?.courseDistributions.length === 0 ? (
        <NoCourses />
      ) : (
        <div
          style={{
            maxHeight: "calc(100vh - 200px)",
            width: "100%",
          }}
        >
          <DataGrid
            getRowId={(row) =>
              `${row.courseDistributionId}-${row.courseTrainingType}`
            }
            rows={data ? data.courseDistributions : []}
            rowCount={data ? data.numberOfEntries : 0}
            columns={columns}
            loading={isLoading}
            disableRowSelectionOnClick
            disableColumnMenu
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[100]}
            paginationMode="server"
          />
        </div>
      )}
    </Stack>
  );
};

export default DistributeCourses;
