import { ICreateChallengeModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById, openModal } from "../features/modals";

import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  Autocomplete,
  Slider,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

import createChallengeSchema, {
  ChallengeBase,
} from "../schemas/createChallenge";

import { track } from "@amplitude/analytics-browser";
import { useEffect, useState } from "react";
import moment, { max, min } from "moment";
import { DatePicker } from "@mui/x-date-pickers";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IDropdownOption } from "../types/common";
import CHALLENGE_STATUSES from "../constants/challengeStatuses";
import MODALS from "../constants/modals";
import Actions from "../components/Modal/Actions";
import { Warning } from "@mui/icons-material";

const CreateChallenge: React.FunctionComponent<ICreateChallengeModalProps> = ({
  id,
  initialValues,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [courses, setCourses] = useState<IDropdownOption[]>([]);
  const [teams, setTeams] = useState<IDropdownOption[]>([]);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm<ChallengeBase>({
    defaultValues: initialValues
      ? {
          ...initialValues,
          startDate: moment.utc(initialValues.startDate).toDate(),
          endDate: moment.utc(initialValues.endDate).toDate(),
        }
      : {
          startDate: moment.utc().startOf("day").toDate(),
          endDate: moment.utc().add(1, "month").endOf("day").toDate(),
          answersGivenCriteria: 80,
          correctAnswersCriteria: 80,
        },
    resolver: yupResolver(createChallengeSchema),
  });

  const onSubmit = async (values: ChallengeBase) => {
    setIsSubmitting(true);

    let warnings = [];

    const enabled = await axios.post<boolean>(
      "/Challenge/IsCourseScheduledInTeam",
      null,
      {
        params: {
          channelId: values.channelId,
          questionCategory: values.questionCategoryId,
        },
      }
    );

    if (!enabled.data) {
      warnings.push(
        "This challenge cannot be delivered because its course is not scheduled in the Schedule Courses tab."
      );
    }

    if (!values.status || values.status === CHALLENGE_STATUSES.Invisible) {
      warnings.push(
        "You can save this challenge, but if you don't click “Enable”, the team will not be able to participate in it."
      );
    }

    if (warnings.length) {
      setIsSubmitting(false);
      dispatch(
        openModal({
          type: MODALS.Approve,
          title: (
            <List>
              {warnings.map((warning, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <Warning />
                  </ListItemIcon>
                  <ListItemText>{warning}</ListItemText>
                </ListItem>
              ))}
            </List>
          ),
          onApprove: () => {
            submitData(values);
          },
        })
      );
    } else {
      submitData(values);
    }
  };

  const submitData = (values: ChallengeBase) => {
    setIsSubmitting(true);
    axios
      .post(
        initialValues
          ? "/Challenge/UpdateChallenge"
          : "/Challenge/CreateChallenge",
        values
      )
      .then((res) => {
        toast("Challenge saved successfully", {
          type: "success",
        });
        callback?.();
        handleClose();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onDelete = () => {
    if (!initialValues) {
      return;
    }

    setIsDeleting(true);
    axios
      .post("/Challenge/DeleteChallenge", null, {
        params: {
          challengeId: initialValues.id,
        },
      })
      .then((res) => {
        callback?.();
        handleClose();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  useEffect(() => {
    setIsDropdownLoading(true);
    axios
      .get<{
        questionCategories: IDropdownOption[];
        channels: IDropdownOption[];
      }>("/Challenge/GetEditOptions")
      .then((res) => {
        setCourses(res.data.questionCategories);
        setTeams(res.data.channels);
      })
      .finally(() => {
        setIsDropdownLoading(false);
      });
  }, []);

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    track("OpenedContentCreate");
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {initialValues ? "Edit challenge" : "Create challenge"}
        </DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <Stack spacing={2}>
            <Controller
              name="questionCategoryId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  loading={isDropdownLoading}
                  options={courses}
                  value={courses.find((x) => x.id === field.value) || null}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course"
                      error={!!errors.questionCategoryId}
                      helperText={errors.questionCategoryId?.message}
                    />
                  )}
                  onChange={(e, value) => field.onChange(value?.id)}
                />
              )}
            />
            <Controller
              name="channelId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  loading={isDropdownLoading}
                  options={teams}
                  value={teams.find((x) => x.id === field.value) || null}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Team"
                      error={!!errors.channelId}
                      helperText={errors.channelId?.message}
                    />
                  )}
                  onChange={(e, value) => field.onChange(value?.id)}
                />
              )}
            />
            <Stack direction="row" spacing={2}>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="Start date"
                    value={moment.utc(field.value)}
                    onChange={(newValue) =>
                      field.onChange(
                        moment.utc(newValue).startOf("day").toDate()
                      )
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.startDate,
                        helperText: errors.startDate?.message,
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="End date"
                    value={moment.utc(field.value)}
                    minDate={max(moment(watch("startDate")), moment())}
                    onChange={(newValue) =>
                      field.onChange(moment.utc(newValue).endOf("day").toDate())
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.endDate,
                        helperText: errors.endDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack>
              <Typography>Lessons completed %</Typography>
              <Controller
                name="answersGivenCriteria"
                control={control}
                render={({ field }) => (
                  <Slider
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={100}
                  />
                )}
              />
            </Stack>
            <Stack>
              <Typography>Correct answers %</Typography>
              <Controller
                name="correctAnswersCriteria"
                control={control}
                render={({ field }) => (
                  <Slider
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={100}
                  />
                )}
              />
            </Stack>

            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={field.value === CHALLENGE_STATUSES.Visible}
                      onChange={(event) => {
                        field.onChange(
                          event.target.checked
                            ? CHALLENGE_STATUSES.Visible
                            : CHALLENGE_STATUSES.Invisible
                        );
                      }}
                    />
                  }
                  label="Enable"
                />
              )}
            />
          </Stack>
          <Actions
            neutral={{
              label: "Cancel",
              callback: handleClose,
            }}
            positive={{
              label: initialValues ? "Save" : "Create",
              callback: () => {},
              isLoading: isSubmitting,
            }}
            negative={
              initialValues && {
                label: "Delete",
                callback: onDelete,
                isLoading: isDeleting,
                approve: {
                  title: "Are you sure you want to delete challenge?",
                },
              }
            }
          />
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CreateChallenge;
