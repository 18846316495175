import React, { useEffect, useState } from "react";
import { IUser } from "../types/user";
import axios from "axios";
import { useAppDispatch } from "../hooks/redux";
import { setUser } from "../features/auth";
import { getLanguages } from "../features/classifiers";
import FullScreenLoader from "../components/FullScreenLoader";

const DataProvider: React.FunctionComponent<{}> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get<IUser>("/auth/me")
      .then((res) => {
        dispatch(setUser(res.data));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, []);

  useEffect(() => {
    dispatch(getLanguages());
  }, []);

  return <>{isLoading ? <FullScreenLoader /> : children}</>;
};

export default DataProvider;
