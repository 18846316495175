import {
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import FsLightbox from "fslightbox-react";
import { getRejectedFileErrorMessage } from "../helpers/dropzone";
import Trash from "./Icons/Trash";
import Upload from "./Icons/Upload";

interface ISingleImageUploadProps {
  label?: string;
  onFileDrop: (acceptedFiles: File) => void;
  onFileDelete: () => void;
  value?: string | File;
  videoUrl?: string;
  disabled?: boolean;
}

const SingleImageUpload: React.FC<ISingleImageUploadProps> = ({
  label,
  onFileDrop,
  onFileDelete,
  value,
  disabled,
  videoUrl,
}) => {
  const [preview, setPreview] = useState<string>();
  const [toggler, setToggler] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (disabled) return;

      if (acceptedFiles.length > 0) {
        onFileDrop(acceptedFiles[0]);
      }
    },
    [disabled]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    maxSize: 1024 * 1024 * 2, // 2MB
    disabled: disabled,
  });

  useEffect(() => {
    if (!value) return;

    if (typeof value === "string") {
      setPreview(value);
      return;
    }

    // create the preview
    const objectUrl = URL.createObjectURL(value);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [value]);

  return (
    <div style={{ flex: 1 }}>
      {value ? (
        <>
          <Stack direction="row" spacing={2} alignItems="center">
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea
                onClick={() => {
                  if (videoUrl) {
                    window.open(videoUrl, "_blank");
                  } else {
                    setToggler((e) => !e);
                  }
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ maxWidth: 345, maxHeight: 160, objectFit: "contain" }}
                  image={preview}
                  alt="uploaded image"
                />
              </CardActionArea>
            </Card>
            <IconButton onClick={onFileDelete}>
              <Trash />
            </IconButton>
          </Stack>
          {preview && (
            <div>
              <FsLightbox
                toggler={toggler}
                sources={[preview]}
                showThumbsOnMount={true}
                type="image"
              />
            </div>
          )}
        </>
      ) : (
        <div {...getRootProps()}>
          <input {...getInputProps()} />

          <TextField
            label={label}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
            placeholder={
              isDragActive
                ? isDragReject
                  ? "Validation error, file won't be uploaded"
                  : "Drop the file here ..."
                : "Drag & drop here or click to choose"
            }
            fullWidth
            InputProps={{
              readOnly: true,
              startAdornment: <Upload width={25} height={25} />,
            }}
            error={isDragReject || fileRejections.length > 0}
            helperText={
              isDragReject
                ? "Only .jpeg and .png files are accepted (max 2MB)"
                : fileRejections.length > 0
                ? getRejectedFileErrorMessage(fileRejections[0], {
                    maxFileSizeMB: 2,
                    maxFiles: 1,
                  })
                : undefined
            }
          />
        </div>
      )}
    </div>
  );
};

export default SingleImageUpload;
