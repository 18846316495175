import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M68 40C68 55.464 55.464 68 40 68C24.536 68 12 55.464 12 40C12 24.536 24.536 12 40 12C55.464 12 68 24.536 68 40Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M39.9678 24V46.4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M39.9678 53.6001V55.2001" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
