import { useCallback, useEffect, useRef, useState } from "react";

import { Link as RouterLink } from "react-router-dom";

import axios from "axios";

import {
  Autocomplete,
  Grid,
  Popover,
  Stack,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import {
  ITeamAnalyticsResponse,
  IEmployeesAnalyticsFilters,
} from "../../../../types/analytics";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import Helper from "../../../../components/Helper";
import { EmployeesAnalyticsHelper } from "../../../../constants/helpers";
import GridToolbar from "../../../../components/Grid/Toolbar";
import xlsx, { IJsonSheet } from "json-as-xlsx";

interface IQueryOptions {
  teamId?: number;
}

const TeamsAnalytics: React.FunctionComponent<{}> = () => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<ITeamAnalyticsResponse>();

  const [filterData, setFilterData] = useState<IEmployeesAnalyticsFilters>();

  const gridRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({});

  const handleXLSXExport = useCallback(() => {
    if (!data) {
      return;
    }

    const exportData: IJsonSheet[] = [
      {
        sheet: "Teams",
        columns: [
          {
            label: "Team",
            value: "teamName",
          },
          {
            label: "Active courses",
            value: "activeCourses",
          },
          {
            label: "Sent lessons",
            value: "lessonsSent",
          },
          {
            label: "Completed lessons",
            value: "completedLessons",
          },
          {
            label: "Completed lessons %",
            value: "completedLessonsPercent",
          },
          {
            label: "Correct completion",
            value: "correctAnswers",
          },
          {
            label: "Correct completion %",
            value: "correctAnswersPercent",
          },
        ],
        // @ts-ignore
        content: data.teamAnalytics,
      },
    ];

    xlsx(exportData, {
      fileName: "teams-analytics",
    });
  }, [data]);

  useEffect(() => {
    axios
      .get<IEmployeesAnalyticsFilters>("/Alytics/GetEmployeeFilter")
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  const loadData = useCallback(() => {
    setLoading(true);

    axios
      .get<ITeamAnalyticsResponse>("/Alytics/GetTeamsAnalytics", {
        params: {
          ...queryOptions,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [queryOptions]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns: GridColDef[] = [
    {
      field: "teamName",
      headerName: "Team",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            to={`/analytics/teams/${params.row.teamId}`}
            component={RouterLink}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "activeCourses",
      headerName: "Active courses",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "lessonsSent",
      headerName: "Sent lessons",
      sortable: false,
      minWidth: 200,
    },
    {
      field: "completedLessons",
      headerName: "Completed lessons",
      sortable: false,
      minWidth: 240,
      renderCell: (params) => {
        return `${params.value} (${params.row.completedLessonsPercent}%)`;
      },
    },
    {
      field: "correctAnswers",
      headerName: "Correct completion",
      sortable: false,
      minWidth: 240,
      renderCell: (params) => {
        return `${params.value} (${params.row.correctAnswersPercent}%)`;
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Team analytics
            </Typography>
            <Helper title={<EmployeesAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>

      <DataGrid
        ref={gridRef}
        disableColumnFilter
        getRowId={(row) => row.teamId}
        rows={data ? data.teamAnalytics : []}
        rowCount={data?.numberOfEntries}
        components={{
          Toolbar: () => {
            return (
              <GridToolbar
                filterCount={
                  Object.values(queryOptions).filter((item) => item).length
                }
                toggleFilter={() => setIsFilterOpen(true)}
                onExportXLSX={handleXLSXExport}
              />
            );
          },
        }}
        columns={columns}
        loading={isLoading}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        pageSizeOptions={[100]}
      />
      <Popover
        open={isFilterOpen}
        anchorEl={gridRef.current}
        onClose={() => setIsFilterOpen(false)}
        style={{
          marginTop: 40,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack spacing={2} sx={{ padding: 2, width: 400, maxWidth: "100%" }}>
          <Typography variant="h6">Filters</Typography>
          <Autocomplete
            options={filterData ? filterData.channelList : []}
            value={
              filterData
                ? filterData.channelList.find(
                    (item) => item.id === queryOptions.teamId
                  )
                : null
            }
            getOptionLabel={(option) => option.teamName}
            getOptionKey={(option) => option.id}
            renderInput={(params) => <TextField {...params} label="Team" />}
            onChange={(e, value) => {
              setQueryOptions((prev) => ({
                ...prev,
                teamId: value ? value.id : undefined,
              }));
            }}
          />
        </Stack>
      </Popover>
    </Stack>
  );
};

export default TeamsAnalytics;
