import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path d="M55.5564 55.6693L24.4437 24.5566" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.4436 55.6693L55.5563 24.5566" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
