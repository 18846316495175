import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
export var reactPlugin = new ReactPlugin();
export var appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPLICATION_INSIGTHS_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();
