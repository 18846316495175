import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M22 13.5H30C30 15.7091 31.7909 17.5 34 17.5H46C48.2091 17.5 50 15.7091 50 13.5H58C60.2091 13.5 62 15.2909 62 17.5V65.5C62 67.7091 60.2091 69.5 58 69.5H22C19.7909 69.5 18 67.7091 18 65.5V17.5C18 15.2909 19.7909 13.5 22 13.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 13.5C30 11.2909 31.7909 9.5 34 9.5H46C48.2091 9.5 50 11.2909 50 13.5C50 15.7091 48.2091 17.5 46 17.5H34C31.7909 17.5 30 15.7091 30 13.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M28 25.5H52" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28 33.5H44" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28 41.5H52" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28 49.5H44" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28 57.5H52" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M38 9.5C37.4477 9.5 37 9.05228 37 8.5C37 7.94772 37.4477 7.5 38 7.5H42C42.5523 7.5 43 7.94772 43 8.5C43 9.05228 42.5523 9.5 42 9.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
