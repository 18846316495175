import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority: "https://login.microsoftonline.com/common/",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginHubRequest: PopupRequest = {
  scopes: ["User.Read", "User.ReadBasic.All"],
};

export const loginWebRequest: PopupRequest = {
  scopes: ["User.Read"],
};

export const groupsRequest: PopupRequest = {
  scopes: ["Group.Read.All"],
};

export const tokenRequest: PopupRequest = {
  scopes: [process.env.REACT_APP_API_SCOPE as string],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
};
