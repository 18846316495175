export enum LessonType {
  Informative = "Informative",
  ScenarioBased = "ScenarioBased",
  QuizBased = "QuizBased",
}

export interface IGenerateLessonType {
  name: string;
}

type GenerateLessonTypeList = {
  [key in LessonType]: IGenerateLessonType;
};

export const GENERATE_LESSON_TYPE_LIST: GenerateLessonTypeList = {
  [LessonType.Informative]: {
    name: "Informative – Provides key insights and essential knowledge",
  },
  [LessonType.ScenarioBased]: {
    name: "Scenario-Based – Apply knowledge in practical situations",
  },
  [LessonType.QuizBased]: {
    name: "Quiz-Based – Reinforce and assess knowledge",
  },
};
