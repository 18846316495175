import * as yup from "yup";

const createPromptSchema = yup.object({
  status: yup.number(),
  name: yup.string().trim().required().min(3).max(38).label("Text"),
});

export type PromptBase = yup.InferType<typeof createPromptSchema>;

export default createPromptSchema;
