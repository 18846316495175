export enum CourseStatus {
  Invisible = 0,
  Visible = 1,
  Deleted = 2,
  Finished = 3,
}

export interface ICourseStatus {
  name: string;
  color: string;
}

type CourseStatusList = {
  [key in CourseStatus]: ICourseStatus;
};

export const COURSE_STATUS_LIST: CourseStatusList = {
  [CourseStatus.Invisible]: {
    name: "Invisible",
    color: "#1c1917",
  },
  [CourseStatus.Visible]: {
    name: "Running",
    color: "#2e7d32",
  },
  [CourseStatus.Deleted]: {
    name: "Deleted",
    color: "#d32f2f",
  },
  [CourseStatus.Finished]: {
    name: "Finished",
    color: "#1c1917",
  },
};
