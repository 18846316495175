export default function () {
  return (
    <svg width="30" height="30" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 68C16.1046 68 17 67.1046 17 66L17 56C17 54.8954 16.1046 54 15 54H11C9.89543 54 9 54.8954 9 56V66C9 67.1046 9.89543 68 11 68H15Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 32C9 30.8954 9.89543 30 11 30H15C16.1046 30 17 30.8954 17 32V36C17 37.1046 16.1046 38 15 38H11C9.89543 38 9 37.1046 9 36V32Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4146 30.5856L27.5861 19.4141"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 68C34.1046 68 35 67.1046 35 66V38C35 36.8954 34.1046 36 33 36H29C27.8954 36 27 36.8954 27 38V66C27 67.1046 27.8954 68 29 68H33Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 14C27 12.8954 27.8954 12 29 12H33C34.1046 12 35 12.8954 35 14V18C35 19.1046 34.1046 20 33 20H29C27.8954 20 27 19.1046 27 18V14Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.688 19.0728L45.3121 27.9262"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51 50C52.1046 50 53 50.8954 53 52V66C53 67.1046 52.1046 68 51 68H47C45.8954 68 45 67.1046 45 66V52C45 50.8954 45.8954 50 47 50H51Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45 29C45 27.8954 45.8954 27 47 27H51C52.1046 27 53 27.8954 53 29V33C53 34.1046 52.1046 35 51 35H47C45.8954 35 45 34.1046 45 33V29Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.6885 27.9262L63.3126 19.0728"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69 68C70.1046 68 71 67.1046 71 66V38C71 36.8954 70.1046 36 69 36H65C63.8954 36 63 36.8954 63 38V66C63 67.1046 63.8954 68 65 68H69Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63 14C63 12.8954 63.8954 12 65 12H69C70.1046 12 71 12.8954 71 14V18C71 19.1046 70.1046 20 69 20H65C63.8954 20 63 19.1046 63 18V14Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
