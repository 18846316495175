import moment from "moment";
import * as yup from "yup";

const createChallengeSchema = yup.object({
  status: yup.number(),
  questionCategoryId: yup.number().required().label("Course"),
  channelId: yup.number().required().label("Team"),
  startDate: yup.date().required(),
  endDate: yup
    .date()
    .required()
    .when("startDate", (startDate: Date, schema: any) => {
      return schema.min(startDate, "End date must be after start date");
    })
    .test({
      name: "is-after-today",
      test: (value) => {
        return moment(value).isAfter(moment());
      },
      message: "End date must be after today",
    }),
  answersGivenCriteria: yup.number().min(0).max(100).required(),
  correctAnswersCriteria: yup.number().min(0).max(100).required(),
});

export type ChallengeBase = yup.InferType<typeof createChallengeSchema>;

export default createChallengeSchema;
