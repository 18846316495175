import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path d="M61.7637 40L17.7637 40" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M39.7637 62L39.7637 18" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
