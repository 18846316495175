import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M8 16C8 13.7909 9.79086 12 12 12H20C22.2091 12 24 13.7909 24 16V64C24 66.2091 22.2091 68 20 68H12C9.79086 68 8 66.2091 8 64V16Z"
        strokeLinecap="round"
      />
      <path
        d="M32 16C32 13.7909 33.7909 12 36 12H44C46.2091 12 48 13.7909 48 16V64C48 66.2091 46.2091 68 44 68H36C33.7909 68 32 66.2091 32 64V16Z"
        strokeLinecap="round"
      />
      <path
        d="M56 16C56 13.7909 57.7909 12 60 12H68C70.2091 12 72 13.7909 72 16V64C72 66.2091 70.2091 68 68 68H60C57.7909 68 56 66.2091 56 64V16Z"
        strokeLinecap="round"
      />
    </svg>
  );
}
