import { IAddUserModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { Dialog, DialogTitle, Button, DialogContent, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import axios from "axios";

import { track } from "@amplitude/analytics-browser";
import { useEffect } from "react";
import addWorkspaceAdminSchema, { WorkspaceAdminBase } from "../schemas/addWorkspaceAdmin";
import { toast } from "react-toastify";
import Actions from "../components/Modal/Actions";

const CreateContent: React.FunctionComponent<IAddUserModalProps> = ({ id, callback }) => {
  const dispatch = useAppDispatch();

  const { values, errors, submitCount, isSubmitting, handleChange, handleSubmit } =
    useFormik<WorkspaceAdminBase>({
      initialValues: {
        email: "",
      },
      validationSchema: addWorkspaceAdminSchema,
      onSubmit: (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        axios
          .post("/access/addWorkspaceAdmin", values)
          .then((res) => {
            callback?.();
            toast("User added successfully", {
              type: "success",
            });
            handleClose();
          })
          .catch((err) => {
            if (err.response?.status === 404) {
              toast("User not found", {
                type: "error",
              });
              return;
            }

            toast("Failed to add user", {
              type: "error",
            });
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    });

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    track("OpenedContentCreate");
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>Add user</DialogTitle>
      <DialogContent style={{ overflow: "visible" }}>
        <Stack spacing={2}>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                handleSubmit();
                ev.preventDefault();
              }
            }}
            disabled={isSubmitting}
            value={values.email}
            error={Boolean(errors.email) && submitCount > 0}
            helperText={submitCount > 0 && errors.email}
            onChange={handleChange}
            fullWidth
          />
        </Stack>
        <Actions
          neutral={{
            label: "Cancel",
            callback: handleClose,
          }}
          positive={{
            label: "Add",
            callback: handleSubmit,
            isLoading: isSubmitting,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateContent;
