import { useEffect, useState } from "react";

import { ISendAnnouncementModalProps } from "../types/modal";
import { IDropdownOption } from "../types/common";
import sendAnnouncementSchema, {
  AnnouncementBase,
} from "../schemas/sendAnnouncement";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";

import { track } from "@amplitude/analytics-browser";
import Actions from "../components/Modal/Actions";
import {
  BoldItalicUnderlineToggles,
  ListsToggle,
  MDXEditor,
  listsPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import Close from "../components/Icons/Close";

const SendAnnouncement: React.FunctionComponent<
  ISendAnnouncementModalProps
> = ({ id, callback }) => {
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [teams, setTeams] = useState<IDropdownOption[]>([]);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<AnnouncementBase>({
    defaultValues: {
      channelIds: [],
    },
    resolver: yupResolver(sendAnnouncementSchema),
  });

  const onSubmit = (values: AnnouncementBase) => {
    setIsSubmitting(true);

    axios
      .post("/Content/SendNotification", values)
      .then((res) => {
        callback?.();
        handleClose();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    setIsDropdownLoading(true);
    axios
      .get<{
        channels: IDropdownOption[];
      }>("/CourseDistributions/GetCoursesAndChannels")
      .then((res) => {
        setTeams(res.data.channels);
      })
      .finally(() => {
        setIsDropdownLoading(false);
      });
  }, []);

  useEffect(() => {
    track("OpenedSendAnnouncement");
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <form>
        <DialogTitle>Send announcement</DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <Stack spacing={2}>
            <Controller
              name="channelIds"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  loading={isDropdownLoading}
                  multiple
                  disableCloseOnSelect
                  options={teams}
                  ChipProps={{
                    deleteIcon: <Close color="#fff" height={20} width={20} />,
                  }}
                  value={
                    teams.filter((x) => field.value.includes(x.id)) || null
                  }
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Teams"
                      error={!!errors.channelIds}
                      helperText={errors.channelIds?.message}
                    />
                  )}
                  onChange={(e, value) => {
                    field.onChange(value.map((x) => x.id));
                  }}
                />
              )}
            />

            <Controller
              name="notificationMessage"
              control={control}
              render={({ field }) => (
                <MDXEditor
                  placeholder="Text"
                  markdown={field.value || ""}
                  onChange={field.onChange}
                  plugins={[
                    listsPlugin(),
                    toolbarPlugin({
                      toolbarContents: () => (
                        <>
                          <BoldItalicUnderlineToggles />
                          <ListsToggle options={["bullet", "number"]} />
                        </>
                      ),
                    }),
                  ]}
                />
              )}
            />
          </Stack>
          <Actions
            neutral={{
              label: "Cancel",
              callback: handleClose,
            }}
            positive={{
              label: "Send",
              callback: handleSubmit(onSubmit),
              isLoading: isSubmitting,
            }}
          />
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default SendAnnouncement;
