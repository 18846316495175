import { Link, Stack, Typography } from "@mui/material";

import prompts from "../assets/img/prompts.png";

export const PromptHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        Prompts are actions that are displayed in Teams app when user answers to
        lesson.
      </Typography>
      <div>
        <img src={prompts} />
      </div>
      <Typography>
        <Link href="/docs/#/courses?id=add-prompts" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const CoursesHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        This section is like a library of all courses available on Ervy. Each
        course consists of lessons focused on a specific theme.
      </Typography>
      <Typography>
        <Link href="/docs/#/courses" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const DistributeCoursesHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography></Typography>
      <Typography>
        <Link href="/docs/#/distribute-courses" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const TeamsHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        In this section, you can see all the teams that have Ervy installed
        organization-wise. There will be at least one team. If necessary, you
        can add more teams, see chapter Add new team to Ervy.
      </Typography>
      <Typography>
        <Link href="/docs/#/teams" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const ChallengesHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        In this section you can set up challenges for teams. Challenges differ
        from courses, because they are terminated, and participants are
        evaluated. To create a challenge, you have to select an existing course
        as a basis, define the challenge start and end date, and set completion
        criteria.
      </Typography>
      <Typography>
        <Link href="/docs/#/challenges" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const TestsHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        In this section, you can create tests for teams. Tests focus on
        assessment and do not contribute to leaderboard scores. Choose a course,
        specify the test's number of questions, and set 'Active from' and
        'Active till' dates. All questions in the test will be distributed
        simultaneously on the 'Active from' date, and participants will receive
        a notification in MS Teams.
      </Typography>
    </Stack>
  );
};

export const LeaderboardsHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        In the Leaderboard section, you can view the monthly ranking of
        participants. This feature enables you to identify the top performing
        Ervy users for each month.
      </Typography>
      <Typography>
        <Link href="/docs/#/leaderboard" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const AnnouncementsHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        The Announcements section is a useful feature for quickly sending
        messages to one or multiple teams, for example, remembering the end of
        the challenge.
      </Typography>
      <Typography>
        <Link href="/docs/#/announcements" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const AccessHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        The Access section displays a list of additional users who have been
        permitted to access Ervy Hub. The user who initially installed the Ervy
        app is not included in the list and can access Ervy Hub by default. All
        users with granted access have equal rights to manage the content of
        Ervy Hub.
      </Typography>
      <Typography>
        <Link href="/docs/#/access" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const LicensesHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        Ervy app offers a 30-day free trial. After that, to access all features,
        you will need to purchase licenses per user, see the pricing plans on
        the Ervy app website. If you have not purchased any licenses yet, you
        will see the message “No licenses purchased. To start using
        microlearning features, please purchase licenses from the marketplace.
        Visit marketplace” on the screen. Click the link Visit marketplace to
        open Microsoft AppSource and purchase licenses.
      </Typography>
      <Typography>
        <Link href="/docs/#/licenseManagement" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const ContentAnalyticsHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        In the Courses subsection, you can see user activity in total and on
        different courses.
      </Typography>
      <Typography>
        <Link href="/docs/#/analytics?id=courses" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const EmployeesAnalyticsHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        The Employees analytics subsection offers information on each employee's
        performance in individual courses. This feature enables you to identify
        which employees may be struggling with certain topics, allowing you to
        provide them with additional training or support.
      </Typography>
      <Typography>
        <Link href="/docs/#/analytics?id=employees" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const LessonsAnalyticsHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        Lessons analytics subsection shows detailed information on the
        question/answer level regarding the courses and individual employees.
      </Typography>
      <Typography>
        <Link href="/docs/#/analytics?id=lessons" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const TrendsAnalyticsHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        The Trends section helps you to analyze participants’ knowledge level of
        different topics in courses.
      </Typography>
      <Typography>
        <Link href="/docs/#/analytics?id=trends" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const ChallengesAnalyticsHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        In this section, you can monitor the progress of your employees and see
        which employees comply with the challenge completion criteria during and
        after the challenge.
      </Typography>
      <Typography>
        <Link href="/docs/#/challenges?id=challenge-analytics" color="#fff">
          Learn more
        </Link>
      </Typography>
    </Stack>
  );
};

export const TestsAnalyticsHelper: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Typography>
        In this section, you can monitor the progress of your employees and see
        which employees comply with the test completion criteria during and
        after the test.
      </Typography>
    </Stack>
  );
};
