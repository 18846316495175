import { Link, Typography } from "@mui/material";

const Copyright: React.FC = () => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mb: 8 }}
    >
      Copyright © Ervy {new Date().getFullYear()}
      {"."} v:{process.env.REACT_APP_VERSION}
    </Typography>
  );
};

export default Copyright;
