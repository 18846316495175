// utils
import { createSlice } from "@reduxjs/toolkit";

export interface ITour {
  run: boolean;
  skipped: boolean;
  finished: boolean;
  index: number;
}

const initialState: ITour = {
  run: true,
  skipped: false,
  finished: false,
  index: 0,
};

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setRun: (state, { payload }: { payload: any }) => {
      state.run = payload;
    },
    setSkipped: (state, { payload }: { payload: any }) => {
      state.skipped = payload;
    },
    setFinished: (state, { payload }: { payload: boolean }) => {
      state.finished = payload;
    },
    nextStep: (state, { payload }: { payload: { stop?: boolean } }) => {
      if (payload.stop) {
        state.run = false;
      }
      state.index += 1;
    },
  },
});

export const { setRun, setSkipped, nextStep, setFinished } = tourSlice.actions;

export default tourSlice.reducer;
