// utils
import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../types/user";

export interface IAuth {
  me?: IUser;
  isAuthenticated: boolean;
}

const initialState: IAuth = {
  isAuthenticated: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: () => initialState,
    setUser: (state, { payload }: { payload: IUser }) => {
      state.me = payload;
    },
    clearUser: (state) => {
      state.me = undefined;
    },
  },
});

export const { clearAuth, setUser, clearUser } = authSlice.actions;

export default authSlice.reducer;
