import { ICreateTestModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById, openModal } from "../features/modals";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  Autocomplete,
  Slider,
  Typography,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import axios from "axios";
import { toast } from "react-toastify";

import createTestSchema, { TestBase } from "../schemas/createTest";

import { track } from "@amplitude/analytics-browser";
import { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IDropdownOption } from "../types/common";
import TEST_STATUSES from "../constants/testStatuses";
import Actions from "../components/Modal/Actions";
import MODALS from "../constants/modals";
import { Warning } from "@mui/icons-material";

const CreateTest: React.FunctionComponent<ICreateTestModalProps> = ({
  id,
  initialValues,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [courses, setCourses] = useState<
    (IDropdownOption & { count: number })[]
  >([]);
  const [teams, setTeams] = useState<IDropdownOption[]>([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<TestBase>({
    defaultValues: initialValues
      ? {
          ...initialValues,
          startDate: moment.utc(initialValues.startDate).toDate(),
          endDate: moment.utc(initialValues.endDate).toDate(),
        }
      : {
          startDate: moment.utc().startOf("day").toDate(),
          endDate: moment.utc().add(1, "month").endOf("day").toDate(),
          correctAnswersCriteria: 80,
        },
    resolver: yupResolver(createTestSchema),
  });

  const onSubmit = (values: TestBase) => {
    if (!values.status || values.status === TEST_STATUSES.Invisible) {
      dispatch(
        openModal({
          type: MODALS.Approve,
          title: (
            <List>
              <ListItem>
                <ListItemIcon>
                  <Warning />
                </ListItemIcon>
                <ListItemText>
                  You can save this test, but if you don't click “Enable”, your
                  team will not receive it.
                </ListItemText>
              </ListItem>
            </List>
          ),
          onApprove: () => {
            submitData(values);
          },
        })
      );
    } else {
      submitData(values);
    }
  };

  const submitData = (values: TestBase) => {
    setIsSubmitting(true);

    axios
      .post(initialValues?.id ? "/Test/UpdateTest" : "/Test/CreateTest", values)
      .then((res) => {
        toast("Test saved successfully", {
          type: "success",
        });
        callback?.();
        handleClose();
        track("CreateTest");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onDelete = () => {
    if (!initialValues) {
      return;
    }

    setIsDeleting(true);
    axios
      .post("/Test/DeleteTest", null, {
        params: {
          testId: initialValues.id,
        },
      })
      .then((res) => {
        callback?.();
        handleClose();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  useEffect(() => {
    setIsDropdownLoading(true);
    axios
      .get<{
        questionCategories: (IDropdownOption & { count: number })[];
        channels: IDropdownOption[];
      }>("/Test/GetEditOptions")
      .then((res) => {
        setCourses(res.data.questionCategories);
        setTeams(res.data.channels);
      })
      .finally(() => {
        setIsDropdownLoading(false);
      });
  }, []);

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    track("OpenedCreateTest");
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{initialValues ? "Edit test" : "Create test"}</DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <Stack spacing={2}>
            <Controller
              name="questionCategoryId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  loading={isDropdownLoading}
                  options={courses}
                  value={courses.find((x) => x.id === field.value) || null}
                  getOptionLabel={(option) => option.name}
                  disabled={
                    initialValues &&
                    initialValues.status === TEST_STATUSES.Visible
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Course"
                      error={!!errors.questionCategoryId}
                      helperText={errors.questionCategoryId?.message}
                    />
                  )}
                  onChange={(e, value) => {
                    field.onChange(value?.id);
                  }}
                />
              )}
            />

            <Stack>
              <Typography>Number of Questions</Typography>
              <Controller
                name="questionCount"
                control={control}
                render={({ field }) => (
                  <Stack>
                    <Slider
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                      }}
                      disabled={
                        !watch("questionCategoryId") ||
                        (initialValues &&
                          initialValues.status === TEST_STATUSES.Visible)
                      }
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={
                        courses.find(
                          (x) => x.id === watch("questionCategoryId")
                        )?.count
                      }
                    />
                    <FormHelperText error={!!errors.questionCount}>
                      {errors.questionCount?.message}
                    </FormHelperText>
                  </Stack>
                )}
              />
            </Stack>

            <Controller
              name="channelId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  loading={isDropdownLoading}
                  options={teams}
                  value={teams.find((x) => x.id === field.value) || null}
                  getOptionLabel={(option) => option.name}
                  disabled={
                    initialValues &&
                    initialValues.status === TEST_STATUSES.Visible
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Team"
                      error={!!errors.channelId}
                      helperText={errors.channelId?.message}
                    />
                  )}
                  onChange={(e, value) => field.onChange(value?.id)}
                />
              )}
            />
            <Stack direction="row" spacing={2}>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="Active from"
                    value={moment.utc(field.value)}
                    onChange={(newValue) =>
                      field.onChange(
                        moment.utc(newValue).startOf("day").toDate()
                      )
                    }
                    disabled={
                      initialValues &&
                      initialValues.status === TEST_STATUSES.Visible
                    }
                    minDate={moment.utc().startOf("day")}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.startDate,
                        helperText: errors.startDate?.message,
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="Active till"
                    value={moment.utc(field.value)}
                    minDate={moment(watch("startDate"))}
                    onChange={(newValue) =>
                      field.onChange(moment.utc(newValue).endOf("day").toDate())
                    }
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!errors.endDate,
                        helperText: errors.endDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Stack>

            <Stack>
              <Typography>Correct answers %</Typography>
              <Controller
                name="correctAnswersCriteria"
                control={control}
                render={({ field }) => (
                  <Stack>
                    <Slider
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                      }}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={100}
                    />
                    <FormHelperText error={!!errors.correctAnswersCriteria}>
                      {errors.correctAnswersCriteria?.message}
                    </FormHelperText>
                  </Stack>
                )}
              />
            </Stack>

            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      disabled={
                        initialValues &&
                        initialValues.status === TEST_STATUSES.Visible
                      }
                      checked={field.value === TEST_STATUSES.Visible}
                      onChange={(event) => {
                        field.onChange(
                          event.target.checked
                            ? TEST_STATUSES.Visible
                            : TEST_STATUSES.Invisible
                        );
                      }}
                    />
                  }
                  label="Enable"
                />
              )}
            />
          </Stack>
          <Actions
            neutral={{
              label: "Cancel",
              callback: handleClose,
            }}
            positive={{
              label: initialValues ? "Save" : "Create",
              callback: () => {},
              isLoading: isSubmitting,
            }}
            negative={
              initialValues && {
                label: "Delete",
                callback: onDelete,
                isLoading: isDeleting,
                approve: {
                  title: "Are you sure you want to delete test?",
                },
              }
            }
          />
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CreateTest;
