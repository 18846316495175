import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M18.0457 24.8187C18.0457 24.2664 18.4934 23.8187 19.0457 23.8187H31.0457C31.5979 23.8187 32.0457 24.2664 32.0457 24.8187V66.8187C32.0457 67.371 31.5979 67.8187 31.0457 67.8187H19.0457C18.4934 67.8187 18.0457 67.371 18.0457 66.8187V24.8187Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M18.0457 31.8168H32.0457" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.0457 59.8168H32.0457" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M32.0457 12.8187C32.0457 12.2664 32.4934 11.8187 33.0457 11.8187H45.0457C45.5979 11.8187 46.0457 12.2664 46.0457 12.8187V66.8187C46.0457 67.371 45.5979 67.8187 45.0457 67.8187H33.0457C32.4934 67.8187 32.0457 67.371 32.0457 66.8187V12.8187Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M32.0457 19.8168H46.0457" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.0457 59.8168H46.0457" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M64.9544 66.5409L59.2941 31.1785L49.0457 32.8189L54.706 68.1813"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.337 65.8767C55.8065 65.049 57.4269 64.4759 59.1328 64.2028C60.9756 63.9078 62.8329 63.9767 64.6086 64.3807"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
