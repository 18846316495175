import * as yup from "yup";

const socialQuestionSchema = yup.object().shape({
  text: yup.string().min(3, "Question must be at least 3 characters"),
  videoUrl: yup
    .string()
    .nullable()
    .url()
    .test(
      "isValid",
      'Unsupported video platform, supported: "youtube.com", "vimeo.com" ',
      (value) => {
        try {
          if (value) {
            const supportedHostnames = [
              "www.youtube.com",
              "youtube.com",
              "www.vimeo.com",
              "vimeo.com",
              "www.youtu.be",
              "youtu.be",
            ];
            const url = new URL(value);

            return supportedHostnames.includes(url.hostname);
          }

          return true;
        } catch (error) {
          return false;
        }
      }
    ),
  infoUrl: yup.string().nullable().url(),
});

export default socialQuestionSchema;
