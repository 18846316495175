import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M16 50L16 64C16 66.2091 17.7909 68 20 68H60C62.2091 68 64 66.2091 64 64V50"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48 35.9997L56.4013 35.9997C59.5401 35.9997 61.0824 32.1785 58.8229 29.9997L43.8005 15.5138C41.7668 13.5527 38.5458 13.5527 36.5121 15.5138L21.4896 29.9997C19.2302 32.1784 20.7725 35.9997 23.9112 35.9997H32L32 53.9997C32 55.1043 32.8955 55.9997 34 55.9997L46 55.9997C47.1046 55.9997 48 55.1043 48 53.9997L48 35.9997Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
