import * as React from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
export function useBlocker(blocker: { (tx: any): void; (arg0: any): void }, when = true) {
  const { navigator } = React.useContext(NavigationContext);
  React.useEffect(() => {
    if (!when) return;
    // @ts-ignore
    const unblock = navigator.block((tx: { retry: () => void }) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

export default function usePrompt(message: string | undefined, when = true, callback: () => void) {
  const blocker = React.useCallback(
    (tx) => {
      if (window.confirm(message)) {
        tx.retry();
      } else {
        callback();
      }
    },
    [message]
  );
  useBlocker(blocker, when);
}
