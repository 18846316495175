import { useCallback, useEffect, useState } from "react";

import { IWorkspaceAdmin, IWorkspaceAdminResponse } from "../../types/access";

import axios from "axios";
import { toast } from "react-toastify";

import { Grid, Typography, Stack, IconButton, Button } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { useAppDispatch } from "../../hooks/redux";
import { openModal } from "../../features/modals";
import MODALS from "../../constants/modals";
import { AccessHelper } from "../../constants/helpers";
import Helper from "../../components/Helper";
import Plus from "../../components/Icons/Plus";
import Trash from "../../components/Icons/Trash";

const Access: React.FunctionComponent<{}> = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<IWorkspaceAdminResponse>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const loadData = useCallback(() => {
    setLoading(true);
    axios
      .get<IWorkspaceAdminResponse>("/access/getWorkspaceAdmins", {
        params: { page: paginationModel.page + 1 },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paginationModel.page]);

  const handleDelete = useCallback(
    (admin: IWorkspaceAdmin) => {
      axios
        .post(
          "/access/removeWorkspaceAdmin",
          {},
          {
            params: {
              userId: admin.id,
            },
          }
        )
        .then((res) => {
          loadData();
          toast("User removed successfully", {
            type: "success",
          });
        })
        .catch((err) => {
          toast("Failed to remove user", {
            type: "error",
          });
          console.log("err:", err);
        });
    },
    [loadData]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "User",
      sortable: false,
      flex: 1,
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => {
                dispatch(
                  openModal({
                    type: MODALS.Approve,
                    title: `Are you sure you want to delete ${params.row.email}?`,
                    onApprove: () => {
                      handleDelete(params.row as IWorkspaceAdmin);
                    },
                  })
                );
              }}
            >
              <Trash />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Access
            </Typography>
            <Helper title={<AccessHelper />} />
          </Stack>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<Plus width={25} height={25} color="#fff" />}
            onClick={() => {
              dispatch(
                openModal({
                  type: MODALS.AddUser,
                  callback: loadData,
                })
              );
            }}
          >
            Add user
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        getRowId={(row) => row.id}
        rows={data ? data.accessList : []}
        rowCount={data?.numberOfEntries}
        columns={columns}
        loading={isLoading}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[100]}
        paginationMode="server"
      />
    </Stack>
  );
};

export default Access;
