import { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Grid, Typography, Button, Stack, Chip, IconButton } from "@mui/material";

import { useAppDispatch } from "../../hooks/redux";
import { openModal } from "../../features/modals";
import MODALS from "../../constants/modals";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { IContentNotification, IContentNotificationResponse } from "../../types/content";
import { AnnouncementsHelper } from "../../constants/helpers";
import Helper from "../../components/Helper";
import Plane from "../../components/Icons/Plane";
import Eye from "../../components/Icons/Eye";

const Challenges: React.FunctionComponent<{}> = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IContentNotificationResponse>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<IContentNotificationResponse>(`/Content/GetNotifications`)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns: GridColDef<IContentNotification>[] = [
    {
      field: "displayName",
      headerName: "Sender",
      sortable: false,
      width: 240,
    },
    {
      field: "channels",
      headerName: "Teams",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          {params.value &&
            params.value.map((row: { id: number; teamName: string }, index: number) => (
              <Chip key={row.id} label={row.teamName} />
            ))}
        </Stack>
      ),
    },
    {
      field: "timeSent",
      headerName: "Time sent",
      sortable: false,
      valueFormatter: (params) => {
        return moment
          .utc(params.value as string)
          .local()
          .format("L LT");
      },
      width: 160,
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              dispatch(
                openModal({
                  type: MODALS.ViewAnnouncement,
                  announcement: params.row,
                })
              );
            }}
          >
            <Eye />
          </IconButton>
        );
      },
      width: 75,
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              Announcements
            </Typography>
            <Helper title={<AnnouncementsHelper />} />
          </Stack>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={
              <Plane
                width={25}
                height={25}
                color="#fff"
                style={{
                  transform: "rotate(90deg)",
                }}
              />
            }
            onClick={() => {
              dispatch(
                openModal({
                  type: MODALS.SendAnnouncement,
                  callback: loadData,
                })
              );
            }}
          >
            Send announcement
          </Button>
        </Grid>
      </Grid>

      <DataGrid
        loading={isLoading}
        getRowId={(row) => moment(row.timeSent).format("x")}
        rows={data?.notifications || []}
        rowCount={data?.numberOfEntries || 0}
        columns={columns}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[100]}
        paginationMode="server"
      />
    </Stack>
  );
};

export default Challenges;
