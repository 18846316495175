import { useEffect, useState } from "react";

import axios from "axios";

import {
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  IAnsweredCorrectQuizzes,
  IGeneralAnalytics,
  ITeamAnalyticsResponse,
} from "../../../types/analytics";

import {
  Chart as ChartJS,
  RadialLinearScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  ArcElement,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";

import { TrendsAnalyticsHelper } from "../../../constants/helpers";
import Helper from "../../../components/Helper";
import CustomPaper from "../../../components/CustomPaper";
import GeneralAnalyticsCard from "../../../components/GenerAnalyticsCard";
import { IChannel } from "../../../types/leaderboard";
import moment from "moment";
import ColorHash from "color-hash";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  LinearScale,
  CategoryScale,
  Filler,
  Tooltip,
  Legend,
  ArcElement
);

const TrendsAnalytics: React.FunctionComponent<{}> = () => {
  var colorHash = new ColorHash({
    hue: { min: 30, max: 45 },
    saturation: [0.8, 0.9],
    lightness: [0.5, 0.65],
  });

  const [channels, setChannels] = useState<IChannel[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<number>(0);

  const [isGeneralLoading, setGeneralLoading] = useState(false);
  const [generalAnalytics, setGeneralAnalytics] = useState<IGeneralAnalytics>();
  const [isChannelLoading, setChannelLoading] = useState(false);

  const [answeredCorrectQuizzes, setAnsweredCorrectQuizzes] = useState<
    IAnsweredCorrectQuizzes[]
  >([]);
  const [contentRatio, setContentRation] = useState<
    { name: string; ratio: number }[]
  >([]);

  useEffect(() => {
    axios
      .get<{ workspaceChannels: IChannel[] }>("/Alytics/GetChannels")
      .then((res) => {
        setChannels(res.data.workspaceChannels);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    axios
      .get<{ answeredCorrectQuizzes: IAnsweredCorrectQuizzes[] }>(
        "/Alytics/GetAnsweredCorrectQuizzes",
        { params: { channelId: selectedChannel } }
      )
      .then((res) => {
        setAnsweredCorrectQuizzes(res.data.answeredCorrectQuizzes);
      })
      .catch((err) => {
        console.log("error:", err);
      });

    axios
      .get<{ contentName: string[]; contentRatio: number[] }>(
        "/Alytics/GetContentDistributionRatio",
        { params: { channelId: selectedChannel } }
      )
      .then((res) => {
        let mapped: { name: string; ratio: number }[] = [];
        res.data.contentName.forEach((element, index) => {
          const ratio = res.data.contentRatio[index];
          mapped.push({
            name: element,
            ratio: ratio ? ratio : 0,
          });
        });
        setContentRation(mapped);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, [selectedChannel]);

  useEffect(() => {
    setGeneralLoading(true);
    setChannelLoading(true);
    axios
      .get<IGeneralAnalytics>("/Alytics/GetGeneralAnalytics", {
        params: {
          channelId: selectedChannel,
        },
      })
      .then((res) => {
        setGeneralAnalytics(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setGeneralLoading(false);
      });

    // axios
    //   .get<ITeamAnalyticsResponse>("/Alytics/GetChannelAnalytics", {
    //     params: {
    //       channelId: selectedChannel,
    //     },
    //   })
    //   .then((res) => {
    //     setTeamAnalytics(res.data);
    //   })
    //   .catch((err) => {
    //     console.log("error:", err);
    //   })
    //   .finally(() => {
    //     setChannelLoading(false);
    //   });
  }, [selectedChannel]);

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography component="h1" variant="h2">
              Trends in team
            </Typography>
            <Select
              variant="filled"
              value={selectedChannel.toString()}
              onChange={(event: SelectChangeEvent) => {
                setSelectedChannel(parseInt(event.target.value));
              }}
              disableUnderline
            >
              {channels.map((channel) => (
                <MenuItem key={channel.id} value={channel.id}>
                  {channel.channelName}
                </MenuItem>
              ))}
            </Select>
            <Helper title={<TrendsAnalyticsHelper />} />
          </Stack>
        </Grid>
      </Grid>

      <Box>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <GeneralAnalyticsCard
              title="Users completed onboarding"
              primaryText={`${generalAnalytics?.completedOnboarding}/${generalAnalytics?.allUsersWithOnboardingSent} (${generalAnalytics?.completedOnboardingPercent}%)`}
              loading={isGeneralLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <GeneralAnalyticsCard
              title="Weekly active users"
              primaryText={`${generalAnalytics?.weeklyActiveUsers}/${generalAnalytics?.completedOnboarding} (${generalAnalytics?.weeklyActiveUsersPercent}%)`}
              loading={isGeneralLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <GeneralAnalyticsCard
              title="Weekly lessons completed"
              primaryText={`${generalAnalytics?.weekyLessonsCompleted}/${generalAnalytics?.weeklyLessonsSent} (${generalAnalytics?.weeklyLessonsCompletedPercent}%)`}
              loading={isGeneralLoading}
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <Stack spacing={1}>
              <Typography variant="h3">
                Completed and correctly completed lessons
              </Typography>
              <CustomPaper sx={{ padding: 2 }}>
                <Line
                  style={{ maxHeight: 600 }}
                  options={{
                    responsive: true,
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label(tooltipItem) {
                            return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
                          },
                        },
                      },
                      legend: {
                        position: "bottom",
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          stepSize: 100,
                        },
                        position: "right",
                      },
                    },
                  }}
                  data={{
                    labels: answeredCorrectQuizzes.map((item) =>
                      moment(item.month, "MM").format("MMM").toUpperCase()
                    ),
                    datasets: [
                      {
                        label: "Completed lessons",
                        data: answeredCorrectQuizzes.map(
                          (item) => item.answeredQuestions
                        ),
                        borderColor: `rgb(245, 212, 188)`,
                        tension: 0.4,
                        fill: true,
                        backgroundColor: `rgba(245, 212, 188, 0.15)`,
                      },
                      {
                        label: "Correct completion",
                        data: answeredCorrectQuizzes.map(
                          (item) => item.correctAnswers
                        ),
                        borderColor: `rgb(244, 121, 35)`,
                        tension: 0.4,
                        fill: true,
                        backgroundColor: `rgba(244, 121, 35, 0.3)`,
                      },
                    ],
                  }}
                />
              </CustomPaper>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={1}>
              <Typography variant="h3">Sent courses</Typography>
              <CustomPaper sx={{ padding: 2 }}>
                <Pie
                  style={{ maxHeight: 600 }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "bottom",
                      },
                      tooltip: {
                        callbacks: {
                          label(tooltipItem) {
                            return `${tooltipItem.label}: ${tooltipItem.formattedValue}%`;
                          },
                        },
                      },
                    },
                  }}
                  data={{
                    labels: contentRatio.map((item) => item.name),
                    datasets: [
                      {
                        label: "Dataset 1",
                        data: contentRatio.map((item) => item.ratio),
                        backgroundColor: contentRatio.map((item) =>
                          colorHash.hex(item.name)
                        ),
                      },
                    ],
                  }}
                />
              </CustomPaper>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default TrendsAnalytics;
