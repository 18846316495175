import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M45.1699 15.1762C44.4199 14.4263 43.4027 14.005 42.3422 14.0049L24.0004 14.0029C21.7911 14.0026 20 15.7936 20 18.0029L20 62.0024C20 64.2116 21.7909 66.0024 24 66.0024H56C58.2091 66.0024 60 64.2116 60 62.0024V31.6607C60 30.5997 59.5785 29.5822 58.8282 28.832L58.6276 28.6314L45.3534 15.3597L45.1699 15.1762Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.4142 15.4145C44.8923 14.8926 44 15.2622 44 16.0002V26.0002C44 28.2094 45.7909 30.0002 48 30.0002H58C58.738 30.0002 59.1077 29.1079 58.5858 28.586"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M49.8926 47.0002H29.8926" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M39.8926 57.0002L39.8926 37.0002" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
