export enum LessonTone {
  Formal = "Formal",
  Motivational = "Motivational",
  Technical = "Technical",
  Friendly = "Friendly",
}

export interface IGenerateLessonTone {
  name: string;
}

type GenerateLessonToneList = {
  [key in LessonTone]: IGenerateLessonTone;
};

export const GENERATE_LESSON_TONE_LIST: GenerateLessonToneList = {
  [LessonTone.Formal]: {
    name: "Formal – Professional and structured",
  },
  [LessonTone.Motivational]: {
    name: "Motivational – Inspiring and encouraging",
  },
  [LessonTone.Technical]: {
    name: "Technical – Precise and detailed",
  },
  [LessonTone.Friendly]: {
    name: "Friendly – Warm and welcoming",
  },
};
