import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M61 20L56.3735 64.4144C56.1612 66.4521 54.4437 68 52.395 68H27.605C25.5563 68 23.8388 66.4521 23.6265 64.4144L19 20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M65 20H15" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M27.8555 19.9986L33.926 12.3865H46.0747L52.1452 19.9986"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
