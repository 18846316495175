import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { persistReducer, persistStore } from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage"; // defaults to localStorage for web
import { persistCombineReducers } from "reduxjs-toolkit-persist";

import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

// reducers
import authReducer from "./features/auth";
import modalsReducer from "./features/modals";
import settingsReducer from "./features/settings";
import classifiersReducer from "./features/classifiers";
import tourReducer, { ITour } from "./features/tour";

let middleware = [];

if (process.env.NODE_ENV === "development") {
  middleware = [thunk, createLogger()];
} else {
  middleware = [thunk];
}

const settingsPersistConfig = {
  key: "settings",
  storage: storage,
};

const tourPersistConfig = {
  key: "tour",
  storage: storage,
};

export const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    modals: modalsReducer,
    settings: persistReducer(
      settingsPersistConfig,
      settingsReducer
    ) as typeof settingsReducer,
    classifiers: classifiersReducer,
    tour: persistReducer(tourPersistConfig, tourReducer) as typeof tourReducer,
  }),
  middleware: middleware,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
