import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M28.6864 12.687C35.9304 9.68638 44.0697 9.68638 51.3138 12.687C58.5578 15.6875 64.3132 21.4429 67.3138 28.687C70.3144 35.931 70.3144 44.0703 67.3138 51.3144C64.3132 58.5584 58.5578 64.3138 51.3138 67.3144C44.0698 70.315 35.9304 70.315 28.6864 67.3144C21.4423 64.3138 15.687 58.5584 12.6864 51.3144C9.6858 44.0703 9.6858 35.931 12.6864 28.687C15.687 21.4429 21.4423 15.6875 28.6864 12.687Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.0011 36.0005C37.7919 36.0005 36.0011 37.7913 36.0011 40.0005C36.0011 42.2096 37.7919 44.0005 40.0011 44.0005C42.2102 44.0005 44.0011 42.2096 44.0011 40.0005C44.0011 37.7913 42.2102 36.0005 40.0011 36.0005Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.9138 28.3535L42.4328 36.8244"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.5436 50.5462L43.4797 41.9766"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.0573 18.6655C40.0776 18.6656 40.0979 18.6657 40.1182 18.6658L40.1246 21.0861C40.1 21.086 40.0755 21.0859 40.0509 21.0858L40.0573 18.6655Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5143 21.4202C29.5319 21.4103 29.5495 21.4004 29.5671 21.3905L30.7828 23.4836C30.7615 23.4955 30.7402 23.5074 30.719 23.5194L29.5143 21.4202Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.674 29.0767C21.6842 29.0596 21.6944 29.0425 21.7046 29.0255L23.8043 30.2304C23.7918 30.251 23.7794 30.2717 23.767 30.2924L21.674 29.0767Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6676 39.7029C18.6679 39.6832 18.6682 39.6636 18.6685 39.644L21.0893 39.6377C21.0888 39.6615 21.0884 39.6854 21.088 39.7092L18.6676 39.7029Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4199 50.4849C21.4104 50.468 21.4009 50.4512 21.3914 50.4343L23.4845 49.2185C23.496 49.2391 23.5075 49.2596 23.5191 49.2802L21.4199 50.4849Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2822 58.4468C29.2656 58.4371 29.249 58.4275 29.2325 58.4178L30.4372 56.3185C30.4574 56.3304 30.4776 56.3422 30.4978 56.3539L29.2822 58.4468Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.1165 61.334C40.0974 61.3341 40.0782 61.3342 40.059 61.3343L40.0527 58.914C40.0761 58.9139 40.0995 58.9138 40.1229 58.9137L40.1165 61.334Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.8986 58.3404C50.882 58.3503 50.8654 58.3602 50.8487 58.37L49.6331 56.2771C49.6533 56.2651 49.6736 56.2531 49.6938 56.241L50.8986 58.3404Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.6516 50.3573C58.6421 50.3745 58.6325 50.3916 58.623 50.4087L56.5236 49.204C56.5352 49.1832 56.5468 49.1623 56.5583 49.1415L58.6516 50.3573Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.3314 39.6436C61.3318 39.6635 61.3321 39.6834 61.3324 39.7033L58.9119 39.7096C58.9116 39.6855 58.9111 39.6614 58.9107 39.6373L61.3314 39.6436Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.3709 29.1526C58.3607 29.1352 58.3504 29.1179 58.3401 29.1005L56.2406 30.3053C56.2531 30.3263 56.2656 30.3472 56.278 30.3682L58.3709 29.1526Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.4134 23.5946L50.6181 21.4954C50.6004 21.4853 50.5828 21.4752 50.5651 21.4652L49.3495 23.5581C49.3708 23.5702 49.3921 23.5824 49.4134 23.5946Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
