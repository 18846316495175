import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  fill = "none",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 5 80 80"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M30.9862 47L49.0867 47C50.9133 47 51.828 44.7916 50.5364 43.5L41.8926 34.8562C40.8675 33.831 39.2054 33.831 38.1803 34.8562L29.5364 43.5C28.2449 44.7916 29.1596 47 30.9862 47Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
