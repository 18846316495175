import { Paper } from "@mui/material";

const MenuItemContainer: React.FC = ({ children }) => {
  return (
    <Paper
      sx={{
        margin: 3,
        border: "1px solid black",
        borderRadius: 4,
        overflowX: "hidden",
        "& > ul:not(:first-of-type)": {
          borderTop: "1px solid black",
        },
      }}
    >
      {children}
    </Paper>
  );
};

export default MenuItemContainer;
