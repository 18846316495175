export default function () {
  return (
    <svg width="30" height="30" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M55.8 64H24.2C22.4327 64 21 62.5674 21 60.8V59.593C21 55.0229 23.8269 50.9294 28.1007 49.3107C35.7678 46.4068 44.2322 46.4068 51.8993 49.3107C56.1731 50.9294 59 55.0229 59 59.593V60.8C59 62.5674 57.5673 64 55.8 64Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.1413 39.0779C38.1874 40.6591 41.8125 40.6591 44.8586 39.0779C47.6603 37.6236 49.6632 34.9907 50.3172 31.9025L50.5218 30.936C51.193 27.7662 50.4741 24.4607 48.5471 21.856L48.2168 21.4096C46.2893 18.8044 43.2406 17.2676 40 17.2676C36.7593 17.2676 33.7106 18.8044 31.7832 21.4096L31.4529 21.856C29.5258 24.4607 28.8069 27.7662 29.4781 30.936L29.6828 31.9025C30.3367 34.9907 32.3396 37.6236 35.1413 39.0779Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 56.1332C8 57.1642 8.83574 57.9999 9.86667 57.9999H21.1155C21.6204 54.5443 23.7464 51.5219 26.8385 49.8813C26.5463 49.7119 26.238 49.5643 25.9154 49.4412C21.4618 47.7416 16.5382 47.7416 12.0846 49.4412C9.625 50.3798 8 52.7392 8 55.3718V56.1332Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9662 39.3064C24.5915 41.0891 23.4394 42.611 21.8251 43.4552C20.055 44.381 17.9437 44.381 16.1736 43.4552C14.5594 42.611 13.4073 41.0891 13.0326 39.3064L12.9027 38.6883C12.5171 36.8535 12.9309 34.9414 14.0406 33.4302L14.2509 33.1436C15.3608 31.6321 17.1241 30.7393 18.9994 30.7393C20.8747 30.7393 22.6379 31.6321 23.7478 33.1436L23.9582 33.4302C25.0679 34.9414 25.4817 36.8535 25.0961 38.6883L24.9662 39.3064Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.1337 57.9999H58.8849C58.3801 54.5443 56.2542 51.5218 53.1621 49.8812C53.4542 49.7118 53.7624 49.5643 54.085 49.4412C58.5386 47.7416 63.4621 47.7416 67.9158 49.4412C70.3754 50.3798 72.0004 52.7392 72.0004 55.3719V56.1332C72.0004 57.1642 71.1647 57.9999 70.1337 57.9999Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.0326 39.3064C55.4073 41.0891 56.5594 42.611 58.1736 43.4552C59.9437 44.381 62.055 44.381 63.8251 43.4552C65.4394 42.611 66.5915 41.0891 66.9662 39.3064L67.0961 38.6883C67.4817 36.8535 67.0679 34.9414 65.9582 33.4302L65.7478 33.1436C64.6379 31.6321 62.8747 30.7393 60.9994 30.7393C59.1241 30.7393 57.3608 31.6321 56.2509 33.1436L56.0405 33.4302C54.9309 34.9414 54.5171 36.8535 54.9027 38.6883L55.0326 39.3064Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
