import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M31.161 18.661C36.8204 16.3168 43.1792 16.3168 48.8387 18.661C54.4981 21.0052 58.9944 25.5016 61.3387 31.161C63.6829 36.8204 63.6829 43.1792 61.3387 48.8387C58.9944 54.4981 54.4981 58.9944 48.8387 61.3387C43.1792 63.6829 36.8204 63.6829 31.161 61.3387C25.5016 58.9944 21.0052 54.4981 18.661 48.8387C16.3168 43.1792 16.3168 36.8204 18.661 31.161C21.0052 25.5016 25.5016 21.0052 31.161 18.661Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.4645 31.4645C38.7283 30.5268 41.2718 30.5268 43.5356 31.4645C45.7993 32.4022 47.5979 34.2007 48.5356 36.4645C49.4732 38.7283 49.4732 41.2718 48.5356 43.5356C47.5979 45.7993 45.7993 47.5979 43.5356 48.5356C41.2718 49.4732 38.7283 49.4732 36.4645 48.5356C34.2007 47.5979 32.4022 45.7993 31.4645 43.5356C30.5268 41.2718 30.5268 38.7283 31.4645 36.4645C32.4022 34.2007 34.2007 32.4022 36.4645 31.4645Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 12L40.008 23.3702"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.6299 40.008L68.0001 40"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.9922 56.6299L40.0002 68.0001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3702 39.9922L12 40.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
