import { useCallback, useEffect, useState } from "react";

import { track } from "@amplitude/analytics-browser";

import _ from "lodash";

import moment from "moment";

import {
  Grid,
  Stack,
  Box,
  Skeleton,
  useMediaQuery,
  Theme,
  IconButton,
  Typography,
  CircularProgress,
  Paper,
  Chip,
} from "@mui/material";

import { IUserCourse } from "../../../types/course";

import Chevron from "../../../components/Icons/Chevron";
import { Link } from "react-router-dom";
import Calendar from "../../../components/Icons/Calendar";
import Hash from "../../../components/Icons/Hash";
import CircleFilled from "../../../components/Icons/CircleFilled";

import { useParams } from "react-router-dom";
import {
  COURSE_TRAINING_TYPE_LIST,
  CourseTrainingType,
} from "../../../constants/courseTrainingType";

import axios from "axios";
import ArchDiagram from "../../../components/Arch";
import {
  COURSE_STATUS_LIST,
  CourseStatus,
} from "../../../constants/courseStatus";
import Lesson from "../../../components/Lesson";

export default function Course() {
  const { courseDistributionId, courseTrainingType } = useParams<{
    courseDistributionId?: string;
    courseTrainingType?: string;
  }>();

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = useState(false);

  const [userCourse, setUserCourse] = useState<IUserCourse>();

  const loadData = useCallback(() => {
    axios
      .get<IUserCourse>(
        `${process.env.REACT_APP_TABS_API_URL}Courses/GetUserCourseLessons`,
        {
          params: {
            courseDistributionId: courseDistributionId,
            courseTrainingType: courseTrainingType,
          },
        }
      )
      .then((res) => {
        setUserCourse(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    loadData();
  }, [loadData]);

  useEffect(() => {
    track("OpenCourse");
  }, []);

  return (
    <Stack style={{ paddingBottom: 16, minHeight: "90vh" }} spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Link
          to="/courses"
          state={{
            back: true,
          }}
        >
          <IconButton>
            <Chevron
              style={{
                transform: "rotate(270deg)",
              }}
            />
          </IconButton>
        </Link>
        {isLoading ? (
          <Skeleton width={200} height={58} />
        ) : (
          <Typography variant="h2" component="h1">
            {userCourse?.courseName}
          </Typography>
        )}
      </Stack>

      {isLoading ? (
        <Stack alignItems="center" justifyContent="center" flex={1}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Box>
            <Grid container>
              <Grid item md={8}>
                <Typography variant="h5" component="h2" fontWeight={700}>
                  Statistics
                </Typography>
                <Paper
                  style={{
                    border: "none",
                    padding: 24,
                  }}
                  elevation={0}
                >
                  <Stack direction="row">
                    <Stack flex={1} spacing={1}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        {userCourse && (
                          <Chip
                            label={
                              COURSE_TRAINING_TYPE_LIST[
                                userCourse.courseTrainingType
                              ].name
                            }
                            style={{
                              backgroundColor:
                                COURSE_TRAINING_TYPE_LIST[
                                  userCourse.courseTrainingType
                                ].color,
                            }}
                          />
                        )}
                        <Typography>training</Typography>
                      </Stack>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Calendar width={48} height={48} />
                        <Typography>
                          {moment.utc(userCourse?.startDate).format("L")}
                          {userCourse?.endDate && (
                            <>
                              {" "}
                              - {moment.utc(userCourse?.endDate).format("L")}
                            </>
                          )}
                        </Typography>
                      </Stack>
                      {userCourse &&
                        [
                          CourseTrainingType.Instant,
                          CourseTrainingType.Regular,
                        ].includes(userCourse.courseTrainingType) && (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Hash width={48} height={48} />
                            <Typography>
                              {userCourse?.lessons.length} lessons
                            </Typography>
                          </Stack>
                        )}
                      {userCourse && (
                        <Stack direction="row">
                          <Chip
                            label={COURSE_STATUS_LIST[userCourse.status].name}
                            style={{
                              backgroundColor:
                                COURSE_STATUS_LIST[userCourse.status].color,
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                    <Stack alignItems="center" flex={1} spacing={2}>
                      {userCourse && (
                        <Stack
                          style={{
                            position: "relative",
                            minHeight: 200,
                          }}
                        >
                          <ArchDiagram
                            primaryText={
                              userCourse?.courseTrainingType ===
                              CourseTrainingType.Mandatory
                                ? `${
                                    !userCourse.lessonsSent
                                      ? 100
                                      : Math.round(
                                          (userCourse.lessonsCorrect /
                                            userCourse.lessonsSent) *
                                            100
                                        )
                                  }%`
                                : `${userCourse.lessonsCorrect} / ${userCourse.lessonsAnswered}`
                            }
                            secondaryText={
                              userCourse?.courseTrainingType !==
                              CourseTrainingType.Regular
                                ? userCourse.courseTrainingType ===
                                  CourseTrainingType.Mandatory
                                  ? `Goal: ${userCourse.correctAnswersCriteria}%`
                                  : `Goal: ${userCourse?.correctAnswersCriteriaCount}/${userCourse.lessonsSent}`
                                : undefined
                            }
                            color={
                              userCourse?.courseTrainingType ===
                              CourseTrainingType.Regular
                                ? "default"
                                : userCourse.lessonsSent === 0 ||
                                  (userCourse.lessonsCorrect /
                                    userCourse.lessonsSent) *
                                    100 >=
                                    userCourse.correctAnswersCriteria
                                ? "success"
                                : "error"
                            }
                            completion={
                              userCourse.lessonsSent === 0
                                ? 85
                                : (userCourse.lessonsCorrect /
                                    userCourse.lessonsAnswered) *
                                  85
                            }
                          />
                        </Stack>
                      )}
                      <Typography
                        style={{
                          color: "#78716c",
                        }}
                      >
                        Successfully completed lessons
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography variant="h5" component="h2" fontWeight={700}>
              Lessons
            </Typography>
            {userCourse && (
              <Grid container spacing={4}>
                {_.zip(
                  ..._.chunk(userCourse.lessons, smDown ? 1 : mdDown ? 2 : 3)
                ).map((column, index) => (
                  <Grid
                    key={index}
                    container
                    item
                    xs
                    spacing={4}
                    direction="column"
                    role="column"
                  >
                    {column.map((quiz) =>
                      quiz ? (
                        <Grid key={quiz.quizId} item>
                          <Lesson
                            quiz={quiz}
                            courseTrainingType={userCourse.courseTrainingType}
                            callback={() => {
                              loadData();
                            }}
                          />
                        </Grid>
                      ) : null
                    )}
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </>
      )}
    </Stack>
  );
}
