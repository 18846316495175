import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M16 24C16 21.7909 17.7909 20 20 20H60C62.2091 20 64 21.7909 64 24V64C64 66.2091 62.2091 68 60 68H20C17.7909 68 16 66.2091 16 64V24Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M53 20V12" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27 20V12" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 32H64" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
