import { useState } from "react";
import _ from "lodash";

import {
  Divider,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import createPromptSchema, { PromptBase } from "../../../schemas/prompt";
import PROMPT_STATUSES from "../../../constants/promptStatuses";
import axios from "axios";
import LoadingIconButton from "../../../components/LoadingIconButton";
import { IPromptResponse } from "../../../types/prompt";

import { useAppDispatch } from "../../../hooks/redux";
import { openModal } from "../../../features/modals";
import MODALS from "../../../constants/modals";
import Helper from "../../../components/Helper";
import { PromptHelper } from "../../../constants/helpers";
import CustomPaper from "../../../components/CustomPaper";
import { LoadingButton } from "@mui/lab";
import Plus from "../../../components/Icons/Plus";
import Edit from "../../../components/Icons/Edit";
import Trash from "../../../components/Icons/Trash";
import Close from "../../../components/Icons/Close";
import Check from "../../../components/Icons/Check";

interface IContentPromptsProps {
  prompts: IPromptResponse[];
  disabled?: boolean;
  questionCategoryId: number;
}

const ContentPrompts: React.FC<IContentPromptsProps> = ({
  questionCategoryId,
  prompts,
  disabled,
}) => {
  const [list, setList] = useState<IPromptResponse[]>(prompts);

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center">
        <Typography variant="h3">Prompts ({list.length})</Typography>
        <Helper
          title={<PromptHelper />}
          slotProps={{
            tooltip: {
              sx: {
                maxWidth: "none",
              },
            },
          }}
        />
      </Stack>

      <CustomPaper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <List>
            {list.map((prompt) => (
              <PromptItem
                disabled={disabled}
                key={prompt.promptId}
                prompt={prompt}
                deleteCallback={() => {
                  setList((prev) =>
                    _.reject(prev, { promptId: prompt.promptId })
                  );
                }}
                updateCallback={(updatedPrompt) => {
                  setList((prev) =>
                    prev.map((p) =>
                      p.promptId === prompt.promptId ? updatedPrompt : p
                    )
                  );
                }}
              />
            ))}
          </List>
          {!disabled && <Divider />}
          {!disabled ? (
            <PromptInput
              questionCategoryId={questionCategoryId}
              callback={(prompt) => {
                setList((prev) => [...prev, prompt]);
              }}
            />
          ) : null}
        </Stack>
      </CustomPaper>
    </Stack>
  );
};

interface IPromptItemProps {
  disabled?: boolean;
  deleteCallback?: () => void;
  updateCallback?: (prompt: IPromptResponse) => void;
  prompt: IPromptResponse;
}

const PromptItem: React.FC<IPromptItemProps> = ({
  prompt,
  deleteCallback,
  updateCallback,
  disabled,
}) => {
  const dispatch = useAppDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<PromptBase>({
    defaultValues: prompt,
    resolver: yupResolver(createPromptSchema),
  });

  const onSubmit = (values: PromptBase) => {
    setIsSubmitting(true);

    axios
      .post<IPromptResponse>("/Content/UpdatePrompt", {
        promptId: prompt.promptId,
        ...values,
      })
      .then((res) => {
        updateCallback?.(res.data);
        setIsEditing(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleDelete = () => {
    setIsDeleting(true);
    axios
      .post(`/Content/DeletePrompt?promptId=${prompt.promptId}`)
      .then(() => {
        deleteCallback?.();
      })
      .catch(() => {})
      .finally(() => {
        setIsDeleting(false);
      });
  };

  if (!isEditing) {
    return (
      <ListItem disableGutters>
        <ListItemText primary={prompt.name} />
        <Switch
          size="small"
          disabled
          checked={prompt.status === PROMPT_STATUSES.Visible}
        />
        {!disabled && (
          <Tooltip title="Edit prompt">
            <IconButton
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
      </ListItem>
    );
  }

  return (
    <ListItem disableGutters>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
        }}
      >
        <Stack direction="row" spacing={1} flex={1} alignItems="flex-start">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={isSubmitting}
                variant="outlined"
                size="small"
                fullWidth
                error={!!errors.name}
                helperText={
                  <Stack direction="row" justifyContent="space-between">
                    <FormHelperText>{errors.name?.message}</FormHelperText>
                    <FormHelperText>({field.value.length}/38)</FormHelperText>
                  </Stack>
                }
                inputProps={{
                  maxLength: 38,
                }}
                InputProps={{
                  endAdornment: (
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          disabled={isSubmitting}
                          size="small"
                          checked={field.value === PROMPT_STATUSES.Visible}
                          onChange={(event) => {
                            field.onChange(
                              event.target.checked
                                ? PROMPT_STATUSES.Visible
                                : PROMPT_STATUSES.Invisible
                            );
                          }}
                        />
                      )}
                    />
                  ),
                }}
              />
            )}
          />
          <LoadingIconButton loading={isSubmitting} type="submit">
            <Check />
          </LoadingIconButton>
          <Tooltip title="Delete">
            <LoadingIconButton
              loading={isDeleting}
              spinnerColor="error"
              onClick={() => {
                dispatch(
                  openModal({
                    type: MODALS.Approve,
                    title: `Are you sure you want to delete prompt?`,
                    onApprove: () => {
                      handleDelete();
                    },
                  })
                );
              }}
            >
              <Trash />
            </LoadingIconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton
              onClick={() => {
                reset();
                setIsEditing(false);
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Stack>
      </form>
    </ListItem>
  );
};

interface IPromptInputProps {
  questionCategoryId: number;
  callback?: (prompt: IPromptResponse) => void;
}

const PromptInput: React.FC<IPromptInputProps> = ({
  questionCategoryId,
  callback,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<PromptBase>({
    defaultValues: {
      name: "",
      status: PROMPT_STATUSES.Visible,
    },
    resolver: yupResolver(createPromptSchema),
  });

  const onSubmit = (values: PromptBase) => {
    setIsSubmitting(true);

    axios
      .post<IPromptResponse>("/Content/CreatePrompt", {
        ...values,
        questionCategoryId: questionCategoryId,
      })
      .then((res) => {
        callback?.(res.data);
        reset();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={isSubmitting}
              variant="outlined"
              fullWidth
              error={!!errors.name}
              helperText={
                <Stack direction="row" justifyContent="space-between">
                  <FormHelperText>{errors.name?.message}</FormHelperText>
                  <FormHelperText>({field.value.length}/38)</FormHelperText>
                </Stack>
              }
              inputProps={{
                maxLength: 38,
              }}
            />
          )}
        />

        <LoadingButton
          aria-label="submit"
          loading={isSubmitting}
          type="submit"
          variant="contained"
          startIcon={<Plus color="#fff" />}
        >
          Add
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default ContentPrompts;
