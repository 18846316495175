export enum LessonFeedbackType {
  Liked = 4,
  Disliked = 5,
}

export interface ILessonFeedbackType {
  emoji: string;
}

type LessonFeedbackTypeList = {
  [key in LessonFeedbackType]: ILessonFeedbackType;
};

export const LESSON_FEEDBACK_TYPE_LIST: LessonFeedbackTypeList = {
  [LessonFeedbackType.Liked]: {
    emoji: "👍",
  },
  [LessonFeedbackType.Disliked]: {
    emoji: "👎",
  },
};
