import { ILanguage } from "../types/classifiers";

// utils
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getLanguages = createAsyncThunk("classifiers/getLanguages", async () => {
  const response = await axios.get<{ languages: ILanguage[] }>("/Content/getLanguages");

  return response.data.languages;
});

interface IClassifiers {
  languages: ILanguage[];
}

const initialState: IClassifiers = {
  languages: [],
};

const classifiersSlice = createSlice({
  name: "classifiers",
  initialState,
  reducers: {
    clear: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLanguages.fulfilled, (state, action) => {
      state.languages = action.payload;
    });
  },
});

export const { clear } = classifiersSlice.actions;

export default classifiersSlice.reducer;
