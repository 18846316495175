import { FileRejection } from "react-dropzone";

interface IOptions {
  maxFileSizeMB: number;
  maxFiles: number;
}

export function getRejectedFileErrorMessage(
  fileRejection: FileRejection,
  options: IOptions
): string {
  switch (fileRejection.errors[0].code) {
    case "file-too-large":
      return `The image file exceeds the maximum allowed size (${options.maxFileSizeMB}MB)`;
    case "too-many-files":
      return "Too many files";
    case "file-invalid-type":
    default:
      return "File type not supported";
  }
}
