import { useEffect, useState } from "react";

import { IUploadContentModalProps } from "../types/modal";

import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";
import axios from "axios";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  LinearProgress,
  Box,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { track } from "@amplitude/analytics-browser";
import FileUpload from "../components/FileUpload";

import { enqueueSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";

import {
  GENERATE_LESSON_TYPE_LIST,
  LessonType,
} from "../constants/generateLessonType";
import {
  GENERATE_LESSON_TONE_LIST,
  LessonTone,
} from "../constants/generateLessonTone";
import _ from "lodash";

const UploadContent: React.FunctionComponent<IUploadContentModalProps> = ({
  id,
  questionCategoryId,
  callback,
}) => {
  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [files, setFiles] = useState<File[]>([]);
  const [progress, setProgress] = useState<{
    loaded: number;
    total: number;
  }>();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      lessonType: LessonType.Informative,
      lessonTone: LessonTone.Formal,
      UserInstructions: "",
    },
  });

  const controller = new AbortController();

  const onSubmit = handleSubmit((data) => {
    if (files.length === 0) {
      return;
    }

    setIsSubmitting(true);

    let formData = new FormData();
    formData.append("questionCategoryId", questionCategoryId.toString());
    formData.append("file", files[0]);

    formData.append("lessonType", data.lessonType.toString());
    formData.append("lessonTone", data.lessonTone.toString());
    formData.append("UserInstructions", data.UserInstructions);

    axios
      .post("/Content/GenerateCourseFromDoc", formData, {
        signal: controller.signal,
        onDownloadProgress: function (progressEvent) {
          setProgress({
            loaded: progressEvent.loaded,
            total: progressEvent.total,
          });
          if (callback) {
            callback();
          }
        },
      })
      .then((res) => {
        enqueueSnackbar("Lessons generated successfully!", {
          variant: "success",
        });
        handleClose();

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });

  const handleClose = () => {
    dispatch(closeModalById(id));
    controller.abort();
  };

  useEffect(() => {
    track("OpenedUploadContent");
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>AI course builder</DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <Stack spacing={2}>
            <Controller
              name="lessonType"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="time" required>
                    Lesson Type
                  </InputLabel>
                  <Select {...field} labelId="time" label="Lesson Type*">
                    {_.map(GENERATE_LESSON_TYPE_LIST, (item, key) => (
                      <MenuItem key={key} value={key}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="lessonTone"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="time" required>
                    Lesson Tone
                  </InputLabel>
                  <Select {...field} labelId="time" label="Lesson Tone*">
                    {_.map(GENERATE_LESSON_TONE_LIST, (item, key) => (
                      <MenuItem key={key} value={key}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="UserInstructions"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Instructions"
                  placeholder="Provide key details about the intended audience, primary topics, and what learners should achieve. E.g., ‘Aimed at beginners in cybersecurity, covering basics of phishing and password protection."
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={5}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
            <FileUpload
              maxFiles={1}
              values={files}
              placeholder="Attach a PDF or Word document to generate more tailored content."
              onFileDrop={(value) => {
                setFiles(value);
              }}
              onFileDelete={(file) => {
                setFiles((prev) => prev.filter((x) => x !== file));
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            variant="contained"
            disabled={files.length === 0}
            loading={isSubmitting}
            type="submit"
          >
            Generate
          </LoadingButton>
        </DialogActions>

        {isSubmitting && (
          <Box
            sx={{
              position: "relative",
            }}
          >
            <LinearProgress
              style={{
                height: 24,
              }}
              variant={progress ? "determinate" : "indeterminate"}
              value={progress ? (progress.loaded / progress.total) * 100 : 0}
            />
            <Typography
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              {progress
                ? `${Math.round((progress.loaded / progress.total) * 100)}%`
                : "0%"}
            </Typography>
          </Box>
        )}
      </form>
    </Dialog>
  );
};

export default UploadContent;
