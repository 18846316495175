import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path d="M61 66H19" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M40 58V66" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5 18.0005C5 15.7912 6.79109 14.0003 9.00038 14.0005L71.0004 14.0063C73.2094 14.0065 75 15.7973 75 18.0063V54.0001C75 56.2093 73.2091 58.0001 71 58.0001H9C6.79086 58.0001 5 56.2093 5 54.0001L5 18.0005Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
