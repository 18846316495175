import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

import ROLES from "./constants/roles";
import { routes } from "./config/routes";
import { routes as clientRoutes } from "./config/clientRoutes";
import { useAppSelector } from "./hooks/redux";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";

import Layout from "./Layout";
import { Stack, Typography } from "@mui/material";

import denied from "./assets/img/denied.svg";
import { RouteType, TRoute } from "./types/route";

import { SnackbarProvider } from "notistack";
import { useEffect } from "react";

import configParams from "./config/params";

type HubProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: HubProps) {
  const navigate = useNavigate();

  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  const me = useAppSelector((state) => state.auth.me);

  useEffect(() => {
    let env = "";

    if (process.env.REACT_APP_ENVIRONMENT) {
      env = ` - ${process.env.REACT_APP_ENVIRONMENT}`;
    }

    document.title = configParams.webDomains.includes(window.location.host)
      ? `Ervy Web${env}`
      : `Ervy Hub${env}`;
  }, []);

  return (
    <MsalProvider instance={pca}>
      <Layout>
        {configParams.webDomains.includes(window.location.host) && me ? (
          <Routes>
            {clientRoutes.map((route) => {
              return renderRoute(route, me.roleId);
            })}
            <Route path="*" element={<Navigate to="/courses" replace />} />
          </Routes>
        ) : me && me.roleId !== ROLES.User ? (
          <Routes>
            {routes.map((route) => {
              return renderRoute(route, me.roleId);
            })}
            <Route path="*" element={<Navigate to="/courses" replace />} />
          </Routes>
        ) : (
          <Stack spacing={2} alignItems="center">
            <Typography variant="h3" component="h1">
              Insufficient permissions
            </Typography>
            <Typography>
              You don't have required permissions to access this content.
              Contact your organization administrator for more information.
            </Typography>
            <img
              src={denied}
              style={{
                width: "100%",
                maxWidth: 520,
              }}
            />
          </Stack>
        )}
        <SnackbarProvider />
      </Layout>
    </MsalProvider>
  );
}

function renderRoute(route: TRoute, roleId: ROLES): React.ReactNode {
  if (route.type === RouteType.Link) {
    return null;
  }

  if (route.allowedRoles) {
    if (!route.allowedRoles.includes(roleId)) {
      return null;
    }
  }

  if (route.type === RouteType.Group) {
    return route.routes.map((e) => renderRoute(e, roleId));
  }

  return (
    <Route key={route.path} path={route.path} element={<route.element />} />
  );
}

export default App;
