export default function () {
  return (
    <svg width="30" height="30" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 13C32 11.9391 32.4214 10.9217 33.1716 10.1716L37.1716 6.17157C38.7337 4.60947 41.2663 4.60948 42.8284 6.17157L46.8284 10.1716C47.5786 10.9217 48 11.9391 48 13V15.3431L44 19.3431L48 23.3431V25.3431L44 29.3431L48 33.3431V41.2142C53.9269 44.1596 58 50.2757 58 57.3431C58 67.2843 49.9411 75.3431 40 75.3431C30.0589 75.3431 22 67.2843 22 57.3431C22 50.2757 26.0731 44.1596 32 41.2142V13Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 61.3433C37.7909 61.3433 36 63.1341 36 65.3433C36 67.5524 37.7909 69.3433 40 69.3433C42.2091 69.3433 44 67.5524 44 65.3433C44 63.1341 42.2091 61.3433 40 61.3433Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
