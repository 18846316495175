import MODALS from "../constants/modals";

import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { nextStep, setFinished, setRun, setSkipped } from "../features/tour";
import { openModal } from "../features/modals";

import { track } from "@amplitude/analytics-browser";

import Joyride, {
  ACTIONS,
  CallBackProps,
  LIFECYCLE,
  Step,
} from "react-joyride";

import configParams from "../config/params";

export default function TourManager() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tour = useAppSelector((state) => state.tour);

  const isHub = !configParams.webDomains.includes(window.location.host);

  const steps: Step[] = [
    {
      target: "body",
      content: "Want to understand how Ervy works?",
      placement: "center",
      locale: {
        next: "Start a quick tour",
        skip: "Skip the tour",
      },
    },
    {
      target: ".courses-grid",
      content:
        "These courses are created by our team. Later, we’ll show you how to schedule lessons from them.",
      disableScrolling: true,
    },
    {
      target: ".create-course-button",
      content:
        "Our library might not cover each and every one of your training needs. Luckily, you can create your own custom courses. Let’s create a course about time management together!",
      disableScrolling: true,
    },
    {
      target: ".create-course-modal-button",
      content: "Let's create our first course.",
    },
    {
      target: ".generate-from-file-button",
      content:
        "Our AI can generate a course from any relevant document. While you might not have a PDF about Time management on hand right now, keep this option in mind for later.",
    },
    {
      target: ".generate-lesson",
      content:
        "Great news: it’s no problem if you don't have a handy pre-made PDF with time management tips! Our AI can still craft a course for you, lesson by lesson. Simply choose a topic and difficulty level, and click “Auto Generate”.",
    },
    {
      target: ".generate-lesson",
      content:
        "To make things interesting, let's see what AI can do if we choose the topic “Time allocation” and difficulty “Easy”",
    },
    {
      target: ".lesson-tab",
      content:
        "Review what the AI came up with and make any edits you need. Then, scroll down, enable the lesson, and click “Save”.",
      spotlightClicks: true,
      placement: "left",
    },
    {
      target: ".lesson-tab",
      content:
        "Try generating a new lesson a few more times (remember to enable and save each lesson!). Click “Next” when your course is ready.",
      spotlightClicks: true,
      placement: "left",
    },
    {
      target: ".distribute-course-button",
      content:
        'Let\'s click the "Distribute Course" button to kick off sharing your new course with the team.',
    },
    {
      target: ".team-distribution-autocomplete",
      content: "Pick a team from the dropdown to distribute this course.",
    },
    {
      target: ".time-distribution-select",
      content:
        "Pick a specific time for lesson distribution that fits your team’s schedule. How about we set it for 1 PM to keep the learning consistent?",
    },
    {
      target: ".days-distribution-select",
      content:
        "Select the days your team will receive lessons. For a balanced routine, let’s go with Mondays, Wednesdays, and Fridays!",
    },
    {
      target: ".schedule-distribution-button",
      content:
        'Let\'s click "Schedule" to confirm. Lessons will now be distributed at the chosen times and on your selected days—right on schedule!',
      locale: {
        last: "Finish the tour",
      },
    },
  ];

  const joyrideCallback = (data: CallBackProps) => {
    switch (data.action) {
      case ACTIONS.SKIP:
        dispatch(setSkipped(true));
        track("SkippedOnboardingTour");
        break;
      case ACTIONS.CLOSE:
        if (data.lifecycle === LIFECYCLE.INIT) {
          dispatch(setRun(false));
        }
        break;
      case ACTIONS.NEXT:
        switch (data.lifecycle) {
          case LIFECYCLE.COMPLETE:
            track("CompletedOnboardingStep", {
              step: tour.index,
            });
            switch (data.index) {
              case 0:
                dispatch(
                  nextStep({
                    stop: false,
                  })
                );
                navigate(`/courses`);
                break;
              case 2:
                dispatch(
                  openModal({
                    type: MODALS.CreateContent,
                    initialValues: {
                      name: "Time Management",
                    },
                  })
                );
                setTimeout(() => {
                  dispatch(
                    nextStep({
                      stop: false,
                    })
                  );
                }, 500);
                break;
              case 3:
                dispatch(
                  nextStep({
                    stop: true,
                  })
                );
                break;
              case 6:
                dispatch(
                  nextStep({
                    stop: true,
                  })
                );
                break;
              case 8:
                dispatch(
                  nextStep({
                    stop: true,
                  })
                );
                navigate(`/distribute-courses`);
                break;
              case 9:
                dispatch(
                  openModal({
                    type: MODALS.DistributeCourse,
                  })
                );
                setTimeout(() => {
                  dispatch(
                    nextStep({
                      stop: false,
                    })
                  );
                }, 500);
                break;
              case 13:
                dispatch(
                  nextStep({
                    stop: true,
                  })
                );
                break;
              default:
                dispatch(
                  nextStep({
                    stop: false,
                  })
                );
            }

            break;
        }

        break;
      default:
        break;
    }
  };

  return (
    <Joyride
      stepIndex={tour.index}
      run={isHub && !tour.finished && !tour.skipped && tour.run}
      showSkipButton
      steps={steps}
      continuous
      disableScrollParentFix
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: "#F47923",
        },
      }}
      hideBackButton
      callback={joyrideCallback}
    />
  );
}
