import { useEffect, useState } from "react";

import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import _ from "lodash";

import {
  Grid,
  Stack,
  FormControlLabel,
  Switch,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import CourseCard from "../../../components/CourseCard";
import { IUserCourse } from "../../../types/course";

import axios from "axios";
import { CourseStatus } from "../../../constants/courseStatus";

export default function MyCourses() {
  const [showOnlyActive, setShowOnlyActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [userCourses, setUserCourses] = useState<IUserCourse[]>([]);

  useEffect(() => {
    setUserCourses([]);
    setIsLoading(true);
    axios
      .get<IUserCourse[]>(
        `${process.env.REACT_APP_TABS_API_URL}Courses/GetUserCourses`
      )
      .then((res) => {
        setUserCourses(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    // track("OpenMyCourses");
  }, []);

  return (
    <Stack style={{ paddingBottom: 16 }} spacing={2}>
      <Typography variant="h2" component="h1">
        My courses
      </Typography>

      <Box>
        <FormControlLabel
          control={
            <Switch
              id="showOnlyActive"
              color="warning"
              checked={showOnlyActive}
              onChange={() => {
                setShowOnlyActive(!showOnlyActive);
              }}
            />
          }
          label={"See active courses only"}
        />
      </Box>

      <Box>
        {isLoading ? (
          <Stack alignItems="center" justifyContent="center" flex={1}>
            <CircularProgress />
          </Stack>
        ) : (
          <Grid container spacing={2}>
            {userCourses
              .filter((item) =>
                showOnlyActive === true
                  ? item.status === CourseStatus.Visible
                  : true
              )
              .map((item) => (
                <Grid
                  key={`${item.courseDistributionId}-${item.courseTrainingType}`}
                  item
                  xs={4}
                  md={3}
                  lg={2}
                  flexGrow={1}
                  display="flex"
                  flexDirection="column"
                >
                  <RouterLink
                    to={`/courses/${item.courseDistributionId}/${item.courseTrainingType}`}
                    style={{
                      textDecoration: "none",
                      flex: 1,
                      display: "flex",
                    }}
                  >
                    <CourseCard item={item} />
                  </RouterLink>
                </Grid>
              ))}
          </Grid>
        )}
      </Box>
    </Stack>
  );
}
