import { Button, Stack, Typography } from "@mui/material";

import noTeams from "../../../assets/img/no-teams.svg";
import Plus from "../../../components/Icons/Plus";

import { openModal } from "../../../features/modals";
import { useAppDispatch } from "../../../hooks/redux";
import MODALS from "../../../constants/modals";

const NoTeams = () => {
  const dispatch = useAppDispatch();

  return (
    <Stack alignItems="center" spacing={1}>
      <img
        src={noTeams}
        style={{
          width: "100%",
          maxWidth: 660,
        }}
      />
      <Typography>Ervy truly shines when you're learning together.</Typography>
      <Typography
        color="primary"
        style={{
          fontWeight: 600,
        }}
      >
        To distribute courses, first add Ervy to a team:
      </Typography>
      <Button
        variant="contained"
        startIcon={<Plus width={25} height={25} color="#fff" />}
        onClick={() => {
          dispatch(
            openModal({
              type: MODALS.TeamType,
            })
          );
        }}
      >
        Add Ervy to a team
      </Button>
    </Stack>
  );
};

export default NoTeams;
