import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { getRejectedFileErrorMessage } from "../helpers/dropzone";

import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";

interface IFileUploadProps {
  label?: string;
  onFileDrop: (acceptedFiles: File[]) => void;
  onFileDelete: (file: File) => void;
  values?: File[];
  disabled?: boolean;
  maxFiles?: number;
  placeholder?: string;
}

const FileUpload: React.FC<IFileUploadProps> = ({
  label,
  onFileDrop,
  onFileDelete,
  values,
  disabled,
  maxFiles = 1,
  placeholder,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (disabled) return;

      onFileDrop(acceptedFiles);
    },
    [disabled]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles,
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    maxSize: 1024 * 1024 * 30, // 30MB
    disabled: disabled || (values && values.length === maxFiles),
  });

  return (
    <div style={{ flex: 1 }}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        <TextField
          sx={{
            fieldset: {
              borderStyle: "dashed",
            },
          }}
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={disabled || (values && values.length === maxFiles)}
          placeholder={
            isDragActive
              ? isDragReject
                ? "Validation error, file won't be uploaded"
                : "Drop the file here ..."
              : placeholder
              ? placeholder
              : "Drag 'n' drop some file here, or click to select file"
          }
          fullWidth
          InputProps={{
            readOnly: true,
            inputProps: {
              style: {
                textAlign: "center",
                height: "4em",
              },
            },
          }}
          error={isDragReject || fileRejections.length > 0}
          helperText={
            isDragReject
              ? "Only .pdf and .docx files are accepted (max 30MB)"
              : fileRejections.length > 0
              ? getRejectedFileErrorMessage(fileRejections[0], {
                  maxFileSizeMB: 30,
                  maxFiles: 1,
                })
              : undefined
          }
          size="small"
        />
      </div>

      {values && (
        <List>
          {values.map((value, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    onFileDelete(value);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemIcon>
                <FilePresentIcon />
              </ListItemIcon>
              <ListItemText primary={value.name} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default FileUpload;
