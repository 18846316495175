// utils
import { createSlice } from "@reduxjs/toolkit";

export interface ISettings {
  fluid: boolean;
}

const initialState: ISettings = {
  fluid: true,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setFluid: (state, { payload }: { payload: any }) => {
      state.fluid = payload;
    },
  },
});

export const { setFluid } = settingsSlice.actions;

export default settingsSlice.reducer;
