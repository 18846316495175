import moment, { weekdays } from "moment";
import * as yup from "yup";

export const distributeRegularCourseSchema = yup
  .object({
    questionCategoryId: yup.number().required().label("Course"),
    channelId: yup.number().required().label("Team"),
    mandatory: yup.boolean(),
    shuffle: yup.boolean(),
    startDate: yup
      .date()
      .when("mandatory", (mandatory: Boolean, schema: any) => {
        return mandatory ? schema.required() : schema.optional();
      }),
    endDate: yup.date().when("mandatory", (mandatory: Boolean, schema: any) => {
      return mandatory
        ? schema
            .required()
            .when("startDate", (startDate: Date, schema: any) => {
              return schema.min(
                moment(startDate),
                "End date must be after start date"
              );
            })
            .test({
              name: "is-after-today",
              test: (value: Date) => {
                return moment(value).isAfter(moment());
              },
              message: "End date must be after today",
            })
        : schema.optional();
    }),
    monday: yup.boolean(),
    tuesday: yup.boolean(),
    wednesday: yup.boolean(),
    thursday: yup.boolean(),
    friday: yup.boolean(),
    saturday: yup.boolean(),
    sunday: yup.boolean(),
  })
  .test({
    name: "weekdays",
    test: function (value) {
      const { createError } = this;
      const selectedDays =
        value.monday ||
        value.tuesday ||
        value.wednesday ||
        value.thursday ||
        value.friday ||
        value.saturday ||
        value.sunday;

      return (
        selectedDays ||
        createError({
          path: "monday",
          message: "One of the weekdays must be selected",
        })
      );
    },
  });

export const distributeInstantCourseSchema = yup.object({
  questionCategoryId: yup.number().required().label("Course"),
  channelId: yup.number().required().label("Team"),
  shuffle: yup.boolean(),
  startDate: yup.date().required(),
  endDate: yup
    .date()
    .required()
    .when("startDate", (startDate: Date, schema: any) => {
      return schema.min(startDate, "End date must be after start date");
    })
    .test({
      name: "is-after-today",
      test: (value) => {
        return moment(value).isAfter(moment());
      },
      message: "End date must be after today",
    }),
});
