import { ICreateTeamProps } from "../types/modal";

import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  Autocomplete,
  CircularProgress,
  Typography,
} from "@mui/material";

import axios from "axios";
import { track } from "@amplitude/analytics-browser";
import { useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { debounce } from "@mui/material/utils";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Actions from "../components/Modal/Actions";
import createTeamSchema, { TeamBase } from "../schemas/createTeam";
import { IUserGroup } from "../types/team";

const CreateTeam: React.FunctionComponent<ICreateTeamProps> = ({ id }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const languages = useAppSelector((state) => state.classifiers.languages);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [inputValue, setInputValue] = useState<string>();
  const [groups, setGroups] = useState<IUserGroup[]>([]);
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<TeamBase>({
    defaultValues: {
      groupId: "",
      teamName: "",
      languageId: 1,
      showTop10Leaderboard: false,
    },
    resolver: yupResolver(createTeamSchema),
  });

  const onSubmit = (values: TeamBase) => {
    setIsSubmitting(true);
    axios
      .post<{
        channelId: number;
        languagelId: number;
      }>("/Teams/CreateAdTeam", values)
      .then((res) => {
        navigate(`/teams/${res.data.channelId}/${res.data.languagelId}`);
        handleClose();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { filterString: string },
          callback: (results?: readonly IUserGroup[]) => void
        ) => {
          setIsDropdownLoading(true);

          axios
            .get<{
              userGroups: IUserGroup[];
            }>("/Teams/GetUserGroups", {
              params: {
                filterString: request.filterString,
              },
            })
            .then((res) => {
              callback(res.data.userGroups);
            });
        },
        400
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (!inputValue || inputValue === "") {
      return undefined;
    }

    fetch({ filterString: inputValue }, (results?: readonly IUserGroup[]) => {
      if (active) {
        setGroups(results ? [...results] : []);
      }
      setIsDropdownLoading(false);
    });

    return () => {
      active = false;
    };
  }, [inputValue]);

  const handleClose = () => {
    dispatch(closeModalById(id));
  };

  useEffect(() => {
    track("OpenedContentCreate");
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={true} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create team</DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <Stack spacing={2}>
            <Controller
              name="groupId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  filterOptions={(x) => x}
                  loading={isDropdownLoading}
                  options={groups}
                  value={groups.find((x) => x.id === field.value) || null}
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    `${option.displayName} (${option.memberCount} members)`
                  }
                  noOptionsText={!inputValue ? "Start typing to search" : "No results found"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Group*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isDropdownLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      error={!!errors.groupId}
                      helperText={errors.groupId?.message}
                    />
                  )}
                  onChange={(e, value) => {
                    field.onChange(value?.id);
                    setInputValue(undefined);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                />
              )}
            />
            <Controller
              name="teamName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Team name"
                  variant="outlined"
                  fullWidth
                  error={!!errors.teamName}
                  helperText={errors.teamName?.message}
                />
              )}
            />
            <Controller
              name="languageId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  loading={!languages}
                  options={languages}
                  value={languages.find((x) => x.languageId === field.value) || null}
                  getOptionLabel={(option) => option.languageName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Language"
                      error={!!errors.languageId}
                      helperText={errors.languageId?.message}
                    />
                  )}
                  onChange={(e, value) => field.onChange(value?.languageId)}
                />
              )}
            />
            <Controller
              name="showTop10Leaderboard"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} checked={field.value} />}
                  label="Limit leaderboard to top 10"
                />
              )}
            />
            <Typography>
              <Typography variant="required">*</Typography> Only users with a role "members" will be
              included in a team.
            </Typography>
          </Stack>
          <Actions
            neutral={{
              label: "Cancel",
              callback: handleClose,
            }}
            positive={{
              label: "Create",
              callback: handleSubmit(onSubmit),
              isLoading: isSubmitting,
            }}
          />
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CreateTeam;
