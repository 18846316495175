import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M28 64L35.6364 16"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.3638 64L52.0001 16"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6923 31.4287L64 31.4287"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9999 48.5713L60.3076 48.5713"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
