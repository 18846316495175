import { Stack, Typography, Skeleton, useMediaQuery } from "@mui/material";

import CustomPaper from "../components/CustomPaper";
import { useTheme } from "@mui/material/styles";

interface IGeneralAnalyticsCard {
  title: string;
  primaryText?: string;
  loading?: boolean;
}

const GeneralAnalyticsCard: React.FunctionComponent<IGeneralAnalyticsCard> = ({
  title,
  primaryText,
  loading,
}) => {
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <CustomPaper>
      <Stack alignItems="center">
        {loading ? (
          <Skeleton
            width={180}
            height={60}
            sx={{
              marginTop: 5,
              marginBottom: 2,
            }}
          />
        ) : (
          <Typography
            variant={lgDown ? "h3" : "h1"}
            component="h2"
            sx={{
              paddingTop: 5,
              paddingBottom: 2,
            }}
          >
            {primaryText}
          </Typography>
        )}
        <Stack
          sx={{
            width: "100%",
          }}
          alignItems="flex-end"
        >
          {loading ? (
            <Skeleton
              width={200}
              height={30}
              sx={{
                marginBottom: 2,
                marginRight: 3,
              }}
            />
          ) : (
            <Typography
              variant={lgDown ? "subtitle2" : "subtitle1"}
              sx={{
                paddingBottom: 2,
                paddingRight: 3,
              }}
            >
              {title}
            </Typography>
          )}
        </Stack>
      </Stack>
    </CustomPaper>
  );
};

export default GeneralAnalyticsCard;
