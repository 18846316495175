enum Difficulty {
  Easy = 1,
  Medium = 2,
  Hard = 3,
}

export const LIST = [
  {
    name: "Easy",
    value: Difficulty.Easy,
    color: [245, 212, 188],
  },
  {
    name: "Medium",
    value: Difficulty.Medium,
    color: [250, 148, 76],
  },
  {
    name: "Hard",
    value: Difficulty.Hard,
    color: [244, 121, 35],
  },
];

export interface IDifficulty {
  name: string;
  color: string;
}

type DifficultyList = {
  [key in Difficulty]: IDifficulty;
};

export const DIFFICULTY_LIST: DifficultyList = {
  [Difficulty.Easy]: {
    name: "Easy",
    color: "#F5D4BC",
  },
  [Difficulty.Medium]: {
    name: "Medium",
    color: "#FA944C",
  },
  [Difficulty.Hard]: {
    name: "Hard",
    color: "#F47923",
  },
};

export default Difficulty;
