import { SVGProps } from "react";

export default function ({
  width = 30,
  height = 30,
  color = "#78716C",
  rotate = 0,
  strokeWidth = 6,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      rotate={rotate}
      stroke={color}
      strokeWidth={strokeWidth}
      {...rest}
    >
      <path
        d="M46 39.1424L68 13.9995H12L34 39.1424V65.9995L46 56.1172V39.1424Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
