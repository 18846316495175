import * as yup from "yup";

const createTeamSchema = yup.object({
  groupId: yup.string().required().label("Group"),
  languageId: yup.number().required(),
  teamName: yup.string().trim().required().min(3).max(50).label("Team name"),
  showTop10Leaderboard: yup.boolean(),
});

export type TeamBase = yup.InferType<typeof createTeamSchema>;

export default createTeamSchema;
