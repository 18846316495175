export default function () {
  return (
    <svg width="30" height="30" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.6274 18.5C35.384 18.5 31.3143 20.1857 28.3137 23.1863L27.6863 23.8137C24.6857 26.8143 23 30.884 23 35.1274V38.0656C23 43.4665 20.8545 48.6461 17.0355 52.4651C16.3725 53.1281 16 54.0273 16 54.9649C16 56.9173 17.5828 58.5001 19.5352 58.5001H60.4648C62.4172 58.5001 64 56.9173 64 54.9649C64 54.0273 63.6275 53.1281 62.9645 52.4651C59.1455 48.6461 57 43.4665 57 38.0656V35.1274C57 30.884 55.3143 26.8143 52.3137 23.8137L51.6863 23.1863C48.6857 20.1857 44.616 18.5 40.3726 18.5H39.6274Z"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.7549 58.5C33.3124 59.4211 33.0718 60.4434 33.0718 61.5C33.0718 63.9752 34.3923 66.2624 36.5359 67.5C38.6795 68.7376 41.3205 68.7376 43.4641 67.5C45.6077 66.2624 46.9282 63.9752 46.9282 61.5C46.9282 60.4434 46.6876 59.4211 46.245 58.5"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 18.5829V15.5C42 14.3954 41.1046 13.5 40 13.5C38.8954 13.5 38 14.3954 38 15.5V18.5829"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.9999 34.5001C63.9999 31.3484 63.3791 28.2275 62.173 25.3157C60.9669 22.4039 59.1991 19.7581 56.9705 17.5295"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 34.5001C16 31.3484 16.6208 28.2275 17.8269 25.3157C19.033 22.4039 20.8008 19.7581 23.0294 17.5295"
        stroke="#78716C"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
