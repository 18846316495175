import { IconButtonProps, IconButton, CircularProgress } from "@mui/material";

interface ILoadingIconButtonProps extends IconButtonProps {
  loading?: boolean;
  spinnerColor?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
}

export default function LoadingIconButton({
  loading,
  color,
  children,
  spinnerColor,
  ...rest
}: ILoadingIconButtonProps) {
  return (
    <div style={{ position: "relative" }}>
      {loading ? (
        <CircularProgress
          style={{ position: "absolute" }}
          color={spinnerColor || (color === "default" ? undefined : color)}
        />
      ) : null}
      <IconButton disabled={loading} color={color} {...rest}>
        {children}
      </IconButton>
    </div>
  );
}
