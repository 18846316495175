import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { openModal } from "../../features/modals";
import MODALS from "../../constants/modals";
import { useAppDispatch } from "../../hooks/redux";

interface IAction {
  label: string;
  callback: () => void;
  isLoading?: boolean;
  approve?: {
    title: string;
  };
  disabled?: boolean;
  className?: string;
}

interface IModalActionsProps {
  neutral?: IAction;
  positive?: IAction;
  negative?: IAction;
}

const Actions: React.FC<IModalActionsProps> = ({
  neutral,
  positive,
  negative,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Stack
      sx={{
        paddingTop: 2,
      }}
      direction="row"
      justifyContent="space-between"
    >
      <Stack direction="row">
        {negative && (
          <LoadingButton
            className={negative.className}
            disabled={negative.disabled}
            onClick={() => {
              if (negative.approve) {
                dispatch(
                  openModal({
                    type: MODALS.Approve,
                    title: negative.approve.title,
                    onApprove: () => {
                      negative.callback();
                    },
                  })
                );
              } else {
                negative.callback();
              }
            }}
            variant="outlined"
            loading={negative.isLoading}
          >
            {negative.label}
          </LoadingButton>
        )}
      </Stack>
      <Stack alignItems="flex-end" spacing={2} direction="row">
        {neutral && (
          <LoadingButton
            className={neutral.className}
            disabled={neutral.disabled}
            onClick={() => {
              if (neutral.approve) {
                dispatch(
                  openModal({
                    type: MODALS.Approve,
                    title: neutral.approve.title,
                    onApprove: () => {
                      neutral.callback();
                    },
                  })
                );
              } else {
                neutral.callback();
              }
            }}
            variant="outlined"
            loading={neutral.isLoading}
          >
            {neutral.label}
          </LoadingButton>
        )}
        {positive && (
          <LoadingButton
            className={positive.className}
            disabled={positive.disabled}
            onClick={() => {
              if (positive.approve) {
                dispatch(
                  openModal({
                    type: MODALS.Approve,
                    title: positive.approve.title,
                    onApprove: () => {
                      positive.callback();
                    },
                  })
                );
              } else {
                positive.callback();
              }
            }}
            type="submit"
            variant="contained"
            loading={positive.isLoading}
          >
            {positive.label}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};

export default Actions;
