import moment from "moment";
import * as yup from "yup";

const createTestSchema = yup.object({
  status: yup.number(),
  questionCategoryId: yup.number().required().label("Course"),
  questionCount: yup
    .number()
    .required()
    .label("Number of Questions")
    .min(1, "Number of Questions is required"),
  channelId: yup.number().required().label("Team"),
  startDate: yup
    .date()
    .required()
    .label("Active from"),
    // .min(moment().startOf("day").toDate(), "Active from is in past"),
  endDate: yup
    .date()
    .required()
    .label("Active till")
    .when("startDate", (startDate: Date, schema: any) => {
      return schema.min(startDate, "Active till is earlier than Active from");
    }),
  correctAnswersCriteria: yup
    .number()
    .min(1, "Correct answers is required")
    .max(100)
    .required("Correct answers"),
});

export type TestBase = yup.InferType<typeof createTestSchema>;

export default createTestSchema;
