import ROLES from "../constants/roles";

export enum RouteType {
  Menu = "Menu",
  Group = "Group",
  Basic = "Basic",
  Link = "Link",
}

interface IRouteBase {
  type: RouteType;
  path: string;
  element: any;
  allowedRoles?: ROLES[];
  className?: string;
}

export interface IMenuRoute extends IRouteBase {
  type: RouteType.Menu;
  name: string;
  icon: JSX.Element;
}

interface IBasicRoute extends IRouteBase {
  type: RouteType.Basic;
}

interface ILinkRoute {
  type: RouteType.Link;
  link: string;
  icon: JSX.Element;
  name: string;
}

interface IGroupRoute {
  type: RouteType.Group;
  name: string;
  icon: JSX.Element;
  routes: IMenuRoute[];
  allowedRoles?: ROLES[];
}

export type TRoute = IMenuRoute | IBasicRoute | IGroupRoute | ILinkRoute;
