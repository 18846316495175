import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

import { Person } from "@microsoft/mgt-react";
import {
  Grid,
  Typography,
  Stack,
  Select,
  SelectChangeEvent,
  MenuItem,
  AvatarGroup,
  Avatar,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  IBadge,
  IChannel,
  ILeaderboardResponse,
  ITimestamps,
} from "../../types/leaderboard";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Helper from "../../components/Helper";
import { LeaderboardsHelper } from "../../constants/helpers";
import params from "../../config/params";
import InfoCircle from "../../components/Icons/InfoCircle";

const Leaderboard: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [channels, setChannels] = useState<IChannel[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<number>(0);
  const [leaderboard, setLeaderboard] = useState<ILeaderboardResponse>();
  const [date, setDate] = useState<Date>(moment().toDate());
  const [dateRange, setDateRange] = useState<ITimestamps>({
    timestampMin: moment().toDate(),
    timestampMax: moment().toDate(),
  });
  const badgesToShow = 3;

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const loadData = useCallback(() => {
    setIsLoading(true);
    axios
      .get<ILeaderboardResponse>("/Leaderboard/GetLeaderboard", {
        params: {
          channelId: selectedChannel,
          leaderbaordTimestamps: date,
          page: paginationModel.page + 1,
        },
      })
      .then((res) => {
        setLeaderboard(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedChannel, date, paginationModel.page]);

  const toggleTop10 = useCallback(
    (enable: boolean) => {
      if (selectedChannel) {
        axios
          .post("/Leaderboard/ToggleTop10Leaderboard", {
            channelId: selectedChannel,
            enable: enable,
          })
          .then((res) => {
            loadData();
          })
          .catch((err) => {
            console.log("error:", err);
          });
      }
    },
    [selectedChannel]
  );

  useEffect(() => {
    axios
      .get<IChannel[]>("/Leaderboard/GetChannels")
      .then((res) => {
        setChannels(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    axios
      .get<ITimestamps>("/Leaderboard/GetTimestamps", {
        params: {
          channelId: selectedChannel,
        },
      })
      .then((res) => {
        setDateRange(res.data);
        setDate(res.data.timestampMax);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  }, [selectedChannel]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (moment(date).isBefore(dateRange.timestampMin)) {
      setDate(dateRange.timestampMin);
      return;
    }

    if (moment(date).isAfter(dateRange.timestampMax)) {
      setDate(dateRange.timestampMax);
      return;
    }
  }, [date, dateRange.timestampMin, dateRange.timestampMax]);

  const columns: GridColDef[] = [
    {
      field: "rank",
      headerName: "Place",
      sortable: false,
      minWidth: 80,
    },
    {
      field: "displayName",
      headerName: "Name",
      renderCell(params) {
        const badgeCount = params.row.badgeList.length;
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Person userId={params.row.aadObjectId} />
            <Typography>{params.value}</Typography>

            <AvatarGroup
              sx={{
                "& .MuiAvatar-root": { width: 24, height: 24, fontSize: 9 },
              }}
              spacing={5}
            >
              {_.slice<IBadge>(
                params.row.badgeList,
                0,
                badgeCount > badgesToShow ? badgesToShow - 1 : badgeCount
              ).map((badge: IBadge) => (
                <Tooltip
                  arrow
                  key={`${badge.channelId}.${badge.badgeId}`}
                  title={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar alt={badge.badgeName} src={badge.badgeUrl} />
                      <Typography variant="caption">
                        {badge.badgeName}
                      </Typography>
                    </Stack>
                  }
                >
                  <Avatar alt={badge.badgeName} src={badge.badgeUrl} />
                </Tooltip>
              ))}
              {badgeCount > badgesToShow ? (
                <Tooltip
                  arrow
                  title={
                    <Stack spacing={0.2}>
                      {_.slice<IBadge>(
                        params.row.badgeList,
                        badgesToShow - 1
                      ).map((badge: IBadge) => (
                        <Stack
                          key={`${badge.channelId}.${badge.badgeId}`}
                          direction="row"
                          spacing={1}
                          alignItems="center"
                        >
                          <Avatar alt={badge.badgeName} src={badge.badgeUrl} />
                          <Typography variant="caption">
                            {badge.badgeName}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  }
                >
                  <Avatar>+{badgeCount - badgesToShow + 1}</Avatar>
                </Tooltip>
              ) : null}
            </AvatarGroup>
          </Stack>
        );
      },
      sortable: false,
      flex: 2,
      minWidth: 260,
    },
    {
      field: "teamCount",
      headerName: "Teams",
      sortable: false,
      minWidth: 95,
    },
    {
      field: "points",
      headerName: "Points",
      sortable: false,
      minWidth: 95,
    },
    {
      field: "answeredQuestions",
      headerName: "Social answers",
      sortable: false,
      minWidth: 250,
    },
    {
      field: "answeredQuizzes",
      headerName: "Completed lessons",
      sortable: false,
      minWidth: 245,
    },
    {
      field: "answeredQuizzesCorrectly",
      headerName: "Correct completion",
      sortable: false,
      minWidth: 230,
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography component="h1" variant="h2">
            Monthly leaderboard in team
          </Typography>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1} alignItems="center">
            <Select
              value={selectedChannel.toString()}
              variant="filled"
              onChange={(event: SelectChangeEvent) => {
                setSelectedChannel(parseInt(event.target.value));
              }}
            >
              {channels.map((channel) => (
                <MenuItem key={channel.id} value={channel.id}>
                  {channel.channelName}
                </MenuItem>
              ))}
            </Select>
            <Helper title={<LeaderboardsHelper />} />
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h5">Date range:</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton
                disabled={moment(dateRange.timestampMin).isSame(date, "month")}
                onClick={() =>
                  setDate((e) => moment(e).subtract(1, "month").toDate())
                }
              >
                <ChevronLeftIcon />
              </IconButton>
              <Typography variant="h5">
                {moment(
                  moment(date).startOf("month").isAfter(dateRange.timestampMin)
                    ? moment(date).startOf("month")
                    : dateRange.timestampMin
                ).format("ll")}{" "}
                -{" "}
                {moment(
                  moment(date).endOf("month").isBefore(dateRange.timestampMax)
                    ? moment(date).endOf("month")
                    : dateRange.timestampMax
                ).format("ll")}
              </Typography>
              <IconButton
                disabled={moment(dateRange.timestampMax).isSame(date, "month")}
                onClick={() =>
                  setDate((e) => moment(e).add(1, "month").toDate())
                }
              >
                <ChevronRightIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      {selectedChannel !== 0 && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <FormControlLabel
            style={{
              marginRight: 0,
            }}
            control={
              <Switch
                checked={leaderboard?.showTop10Leaderboard}
                onChange={(e) => toggleTop10(e.target.checked)}
                color="primary"
                inputProps={{
                  "aria-label": "Limit MS Teams leaderboard to top 10",
                }}
              />
            }
            label="Limit MS Teams leaderboard to top 10"
          />
          <Tooltip
            title="When enabled, this toggle limits the leaderboard displayed on MS Teams and Web app to show only the top 10 employees within the selected team. This is useful for focusing on the highest performers."
            placement="right"
            arrow
          >
            <span
              style={{
                lineHeight: 0,
              }}
            >
              <InfoCircle width={20} height={20} />
            </span>
          </Tooltip>
        </Stack>
      )}

      <DataGrid
        columnVisibilityModel={{
          teamCount: selectedChannel === 0,
        }}
        getRowId={(row) => row.userId}
        rows={leaderboard ? leaderboard.hubLeaderboardData : []}
        rowCount={leaderboard?.numberOfEntries}
        columns={columns}
        loading={isLoading}
        autoHeight
        disableRowSelectionOnClick
        disableColumnMenu
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[100]}
        paginationMode="server"
      />
    </Stack>
  );
};

export default Leaderboard;
