export enum ExpectedAnswer {
  Incorrect = 0,
  Correct = 1,
}

export interface IExpectedAnswer {
  name: string;
}

type ExpectedAnswersList = {
  [key in ExpectedAnswer]: IExpectedAnswer;
};

export const EXPECTED_ANSWERS_LIST: ExpectedAnswersList = {
  [ExpectedAnswer.Incorrect]: {
    name: "False",
  },
  [ExpectedAnswer.Correct]: {
    name: "True",
  },
};
